import { Input } from "components/index";
import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
const propTypes = {
  className: PropTypes.string,
};

const defaultProps = {
  className: "",
};

const InputComponent = React.forwardRef(
  (
    {
      input,
      meta: { touched, error },
      className,
      label,
      type,
      disabled,
      placeholder,
      withoutError,
      hideEmptyError,
      hideLabel,
      description,
      autoComplete,
      autoFocus,
      ...rest
    },
    ref
  ) => {
    const intl = useIntl();

    const showError = touched && error && !withoutError;

    const errorMessage = error?.messageType
      ? intl.formatMessage(error.messageType)
      : error;

    return (
      <Input
        ref={ref}
        type={type}
        label={label}
        className={className}
        disabled={disabled}
        placeholder={placeholder}
        error={showError ? errorMessage : ""}
        hideEmptyError={hideEmptyError}
        hideLabel={hideLabel}
        description={description}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        {...input}
        {...rest}
      />
    );
  }
);

InputComponent.propTypes = propTypes;
InputComponent.defaultProps = defaultProps;

export default InputComponent;
