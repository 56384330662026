import ls from 'local-storage';

const lsKey = {
  TOKEN: 'token'
};

export default {
  setUserSessionToken: token => ls.set(lsKey.TOKEN, token),
  getUserSessionToken: () => ls.get(lsKey.TOKEN),
  removeUserSessionToken: () => ls.remove(lsKey.TOKEN)
};
