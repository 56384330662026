import { pick } from 'lodash';

const _getModuleState = state => state.session;

export const getUserIsAuthorized = state => _getModuleState(state)?.isAuthorized;

export const getUserData = state => _getModuleState(state)?.auth;
export const getUserEmail = state => _getModuleState(state)?.auth?.email;
export const getIsUserVerifiedEmail = state => !!_getModuleState(state)?.auth?.email_verified_at;
export const getUserToken = state => _getModuleState(state)?.auth?.token;
export const getUserRole = state => _getModuleState(state)?.auth?.role;
export const getSessionStatus = (state, statusType) =>
  _getModuleState(state)?.statuses?.[statusType] || false;
export const getSessionError = (state, errorType) =>
  _getModuleState(state)?.errors?.[errorType] || false;

export const getUserNotificationSettings = state => _getModuleState(state)?.auth?.notify_settings;
export const getUserNotificationReceiveMethod = state =>
  pick(_getModuleState(state)?.auth, ['notify_db', 'notify_email']);
