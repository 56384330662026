import './component_wrapper.scss';

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const ComponentWrapper = ({ name, children }) => {
  const mainClassName = 'component-wrapper';

  return (
    <div className={mainClassName}>
      <h4 className={`${mainClassName}__component-name`}>{name}</h4>
      <div className={`${mainClassName}__preview`}>{children}</div>
    </div>
  );
};

ComponentWrapper.propTypes = propTypes;
ComponentWrapper.defaultProps = defaultProps;

export default ComponentWrapper;
