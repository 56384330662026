const flattenObject = ob => {
  const toReturn = {};

  for (const [key, value] of Object.entries(ob)) {
    if (typeof value === 'object') {
      const flatObject = flattenObject(value);
      for (const [keyFlatten, valueFlatten] of Object.entries(flatObject)) {
        toReturn[`${key}.${keyFlatten}`] = valueFlatten;
      }
    } else {
      toReturn[key] = ob[key];
    }
  }
  return toReturn;
};

export default flattenObject;
