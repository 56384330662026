import "./basic_layout.scss";

import React from "react";
import { ReactComponent as LogoIcon } from "icons/logo.svg";

const mainClassName = "basic-layout";

class BasicLayout extends React.Component {
  render() {
    const { children, headerActions } = this.props;
    return (
      <main className={mainClassName}>
        <header className={`${mainClassName}__header`}>
          <div className={`${mainClassName}__logo-wrapper`}>
            <a href="https://forsecure.pl/">
              <LogoIcon width={145} height={45} />
            </a>
          </div>
          <div className={`${mainClassName}__right-actions`}>
            {headerActions}
          </div>
        </header>
        <div className={`${mainClassName}__content`}>{children}</div>
      </main>
    );
  }
}

export default BasicLayout;
