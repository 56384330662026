import "./input.scss";

import React from "react";
import classnames from "classnames";

import { toastr } from "react-redux-toastr";
import copyTextToClipboard from "copy-text-to-clipboard";

import { ReactComponent as IcoEyeIcon } from "icons/eye.svg";
import { ReactComponent as IcoEyeSlashIcon } from "icons/eye-slash.svg";
import { ReactComponent as CopyIcon } from "icons/copy.svg";

const mainClassName = "input";

class Input extends React.Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }
  state = {
    showPass: false,
  };

  togglePasswordVisible = () =>
    this.setState((state) => ({ showPass: !state.showPass }));

  onCopy = () => {
    const { value } = this.props;

    copyTextToClipboard(value);

    toastr.success("Zawartość pola została skopiowana do schowka.");
  };

  focusInput() {
    this.inputRef.current.focus();
  }

  renderInput = () => {
    const {
      type,
      rows,
      name,
      id,
      onChange,
      value,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      autoComplete,
      autoFocus,
    } = this.props;

    const commonProps = {
      className: classnames(`${mainClassName}__input`, {
        [`${mainClassName}__input--disabled`]: disabled,
      }),
      ref: this.inputRef,
      id,
      name,
      value,
      onChange,
      onFocus,
      onBlur,
      placeholder,
      disabled,
      autoComplete,
      autoFocus,
    };

    switch (type) {
      case "textarea":
        return <textarea rows={rows ? rows : 5} {...commonProps} />;

      default:
        return (
          <input type={!this.state.showPass ? type : "text"} {...commonProps} />
        );
    }
  };

  renderIcon() {
    const { type, copy, value } = this.props;

    const commonProps = {
      height: 18,
      width: 18,
      className: `${mainClassName}__input-icon`,
    };
    const { showPass } = this.state;

    if (!value) {
      return null;
    }

    if (type === "password") {
      const PasswordIcon = showPass ? IcoEyeSlashIcon : IcoEyeIcon;

      if (copy) {
        return !showPass ? (
          <PasswordIcon {...commonProps} onClick={this.togglePasswordVisible} />
        ) : (
          <CopyIcon {...commonProps} onClick={this.onCopy} />
        );
      } else {
        return (
          <PasswordIcon {...commonProps} onClick={this.togglePasswordVisible} />
        );
      }
    }

    if (copy) {
      return <CopyIcon {...commonProps} onClick={this.onCopy} />;
    }

    return null;
  }

  render() {
    const {
      label,
      type,
      copy,
      name,
      value,
      hideEmptyError,
      error,
      disabled,
      hideLabel,
      description,
      withoutLabelAnimation,
    } = this.props;
    const classes = classnames(mainClassName, {
      [`${mainClassName}--is-filled`]: !!value?.length,
      [`${mainClassName}--copy`]: copy && value,
      [`${mainClassName}--pass`]: type === "password",
      [`${mainClassName}--disabled`]: disabled,
      [`${mainClassName}--hide-label`]: hideLabel,
    });

    return (
      <div className={classes}>
        <div style={{ position: "relative" }}>
          {this.renderInput()}
          {this.renderIcon()}
        </div>
        {!hideLabel && (
          <label
            className={classnames(`${mainClassName}__label`, {
              [`${mainClassName}__label--without-animation`]: withoutLabelAnimation,
            })}
            htmlFor={name}
          >
            {label}
          </label>
        )}

        {description && (
          <small className={`${mainClassName}__description`}>
            {description}
          </small>
        )}
        {(!hideEmptyError || !!error) && (
          <div
            className={classnames(`${mainClassName}__error-field`, {
              [`${mainClassName}__error-field--filled`]: !!error,
            })}
          >
            {error || ""}
          </div>
        )}
      </div>
    );
  }
}

export default Input;
