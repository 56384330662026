import emailVerificationMessages from 'modules/Session/EmailVerification/emailVerificationMessages';
import React from 'react';
import { useIntl } from 'react-intl';

import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { mainPaths } from 'const/paths';
import { verificationEmail } from 'modules/Session/actions';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import queryStringParse from 'utils/queryStringParse';

const EmailVerification = ({ location, match, history }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { expires, signature } = queryStringParse(location.search);
  const { id } = match.params;

  dispatch(verificationEmail({ id, expires, signature }))
    .then(() => {
      toastr.success(
        intl.formatMessage(
          emailVerificationMessages.SESSION__EMAIL_VERIFICATION__EMAIL_VERIFIED_SUCCESS
        )
      );
      history.replace(mainPaths.LOGIN);
    })
    .catch(() => {
      toastr.error(
        intl.formatMessage(
          emailVerificationMessages.SESSION__EMAIL_VERIFICATION__EMAIL_VERIFIED_FAILURE
        )
      );
      history.replace(mainPaths.LOGIN);
    });

  return (
    <CircleLoader
      message={intl.formatMessage(
        emailVerificationMessages.SESSION__EMAIL_VERIFICATION__VERIFICATION_LOADING
      )}
      height="100%"
    />
  );
};

export default EmailVerification;
