import './button.scss';

import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import SmallCircleLoader from 'components/SmallCircleLoader/SmallCircleLoader';

const mainClassName = 'button';

const propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  kind: PropTypes.oneOf(['standard', 'outline', 'simple', 'link']),
  withBrackets: PropTypes.bool,
  fill: PropTypes.bool,
  loading: PropTypes.bool
};

const defaultProps = {
  className: '',
  color: 'default',
  type: 'button',
  disabled: false,
  size: 'normal',
  kind: 'standard',
  withBrackets: false,
  fill: false,
  loading: false
};

const Button = ({
  className,
  onClick,
  type,
  disabled,
  kind,
  children,
  withBrackets,
  color,
  fill,
  loading
}) => {
  const classes = classnames(
    mainClassName,
    {
      [`${mainClassName}--kind-${kind}`]: kind,
      [`${mainClassName}--disabled`]: disabled,
      [`${mainClassName}--red`]: color === 'red',
      [`${mainClassName}--gray`]: color === 'gray',
      fill: !!fill
    },
    className
  );

  return (
    <button
      className={classes}
      onClick={disabled || loading ? () => {} : onClick}
      disabled={disabled || loading}
      type={type}
    >
      {loading && (
        <div className={`${mainClassName}__loader-wrapper`}>
          <SmallCircleLoader size={12} />
        </div>
      )}
      {withBrackets && <span>{`<`}</span>}
      {children}
      {withBrackets && <span>{`/>`}</span>}
    </button>
  );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
