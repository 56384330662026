// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import "core-js";
import * as serviceWorker from "./serviceWorker";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import ReduxToastr from "react-redux-toastr";
import { Router } from "react-router-dom";

import history from "utils/history";

import App from "./App";

import "styles/app.scss";

import configureStore from "./configureStore";
import IntlContext from "./IntlContext";

if (process.env.NODE_ENV !== "production") {
  const originalConsoleError = console.error;
  if (console.error === originalConsoleError) {
    console.error = (...args) => {
      if (args?.[0]?.code?.indexOf?.("MISSING_TRANSLATION") === 0) {
        return;
      }
      originalConsoleError.call(console, ...args);
    };
  }
}

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <CookiesProvider>
          <IntlContext.Provider>
            <App />
            <ReduxToastr
              timeOut={4000}
              newestOnTop
              preventDuplicates
              position="top-right"
              transitionIn="fadeIn"
              transitionOut="fadeOut"
              progressBar
              closeOnToastrClick
            />
          </IntlContext.Provider>
        </CookiesProvider>
      </Router>
    </Provider>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

serviceWorker.register();
