import "modules/Session/ResetPass/reset_password.scss";

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useHistory, useLocation } from "react-router";
import { Field, Form, reduxForm } from "redux-form";
import { trim } from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

import LinkButton from "components/LinkButton/LinkButton";
import { mainPaths } from "const/paths";

import InputComponent from "components/FormComponents/InputComponent";
import Button from "components/Button/Button";
import BasicLayout from "components/BasicLayout/BasicLayout";

import { resetUserPassword } from "modules/Session/actions";
import resetPassMessages from "modules/Session/ResetPass/resetPassMessages";
import fieldValidation from "libs/reduxForm/fieldValidation";
import { useFieldValidate } from "libs/reduxForm/hooks/useFieldValidate";

import { ReactComponent as ChevronCircleLeftIcon } from "./circle-left-solid-arrow.svg";

const mainClassName = "reset-password";
const mainMessageId = "session.reset_pass";

const registerEmailRequired = fieldValidation.createRequired(
  resetPassMessages.SESSION__RESET_PASS__VALIDATION_EMAIL_IS_EMPTY
);

const ResetPass = ({ handleSubmit, submitting }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const emailValidation = useFieldValidate([
    registerEmailRequired,
    fieldValidation.email,
  ]);

  const onSubmit = (values) => {
    const { email } = values;

    return dispatch(resetUserPassword(email))
      .then(() => {
        history.push(mainPaths.LOGIN);
        toastr.success(
          intl.formatMessage(
            resetPassMessages.SESSION__RESET_PASS__SUBMIT_SUCCESS
          )
        );
      })
      .catch((error) => {
        toastr.error(
          intl.formatMessage(
            resetPassMessages.SESSION__RESET_PASS__SUBMIT_FAILURE
          )
        );
        console.error(error);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {intl.formatMessage(
            resetPassMessages.SESSION__RESET_PASS__PAGE_HEADER_TITTLE
          )}
        </title>
      </Helmet>
      <BasicLayout
        headerActions={
          <>
            <LinkButton
              to={{
                ...location,
                pathname: "/login",
              }}
              withBrackets
              kind="outline"
              disabled={submitting}
            >
              <FormattedMessage
                id={`${mainMessageId}.log_in_button`}
                defaultMessage="Zaloguj się"
              />
            </LinkButton>
          </>
        }
      >
        <div className={mainClassName}>
          <h2 className={`${mainClassName}__form-title`}>
            {intl.formatMessage(
              resetPassMessages.SESSION__RESET_PASS__PAGE_FORM_TITTLE
            )}
          </h2>
          <Form
            className={`${mainClassName}__form`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Field
              name="email"
              component={InputComponent}
              label={intl.formatMessage(
                resetPassMessages.SESSION__RESET_PASS__EMAIL_FIELD_LABEL
              )}
              validate={emailValidation}
              autoComplete="current_password"
              normalize={(value) => trim(value)}
              autoFocus
            />
            <div className={`${mainClassName}__submit-wrapper`}>
              <Button
                withBrackets
                kind="outline"
                type="submit"
                className="btn_custom"
              >
                {intl.formatMessage(
                  resetPassMessages.SESSION__RESET_PASS__SUBMIT_BUTTON
                )}
              </Button>
            </div>
          </Form>

          <div className={`${mainClassName}__back-wrapper`}>
            <Link className={`${mainClassName}__back-icon`} to="/login">
              <ChevronCircleLeftIcon height={50} width={50} />
            </Link>
          </div>
        </div>
      </BasicLayout>
    </>
  );
};

export default reduxForm({
  form: "ResetUserPassword",
})(ResetPass);
