import './circle_loader.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';

export default class CircleLoader extends Component {
  static propTypes = {
    className: PropTypes.string,
    margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showTimeout: PropTypes.number
  };

  static defaultProps = {
    className: '',
    margin: 20,
    message: '',
    height: 'auto',
    showTimeout: 150
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false
    };

    this.showTimeout = setTimeout(
      () => this._ismounted && this.setState({ show: true }),
      props.showTimeout
    );
  }

  componentDidMount() {
    this._ismounted = true;
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  renderLoader() {
    return (
      <>
        <div className="blobs">
          <div className="blob-center" />
          <div className="blob" />
          <div className="blob" />
          <div className="blob" />
          <div className="blob" />
          <div className="blob" />
          <div className="blob" />
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                result="goo"
              />
              <feBlend in="SourceGraphic" in2="goo" />
            </filter>
          </defs>
        </svg>
      </>
    );
  }

  render() {
    const { className, message, margin, height } = this.props;
    const { show } = this.state;
    const classes = classnames('circle-loader', { show }, className);

    return (
      <div className={classes} style={{ margin, height }}>
        {this.renderLoader(height)}
        {message && (
          <div className="circle-loader-message">
            {message}
            <span>...</span>
          </div>
        )}
      </div>
    );
  }
}
