import "./register_confirm.scss";

import React from "react";

import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

import { BasicLayout } from "components/index";
import LinkButton from "components/LinkButton/LinkButton";

import registerConfirmMessages from "modules/Session/RegisterConfirm/registerConfirmMessages";

const mainClassName = "register-confirm";

const Thanks = (props) => {
  const intl = useIntl();

  return (
    <BasicLayout>
      <div className={mainClassName}>
        <Helmet>
          <title>
            {intl.formatMessage(
              registerConfirmMessages.SESSION__REGISTER_CONFIRM__PAGE_HEADER_TITLE
            )}
          </title>
        </Helmet>
        <h2 className={`${mainClassName}__title`}>
          {intl.formatMessage(
            registerConfirmMessages.SESSION__REGISTER_CONFIRM__PAGE_TITLE
          )}
        </h2>
        <p className={`${mainClassName}__description`}>
          {intl.formatMessage(
            registerConfirmMessages.SESSION__REGISTER_CONFIRM__MESSAGE
          )}
        </p>
        <div className={`${mainClassName}__actions`}>
          <div className={`${mainClassName}__login-button`}>
            <LinkButton
              to={{
                ...props.location,
                pathname: "/login",
              }}
              kind="outline"
              fill
            >
              {intl.formatMessage(
                registerConfirmMessages.SESSION__REGISTER_CONFIRM__LOGIN
              )}
            </LinkButton>
          </div>
          <div className={`${mainClassName}__go-main-button`}>
            <LinkButton
              href="https://forsecure.pl/"
              kind="outline"
              withBrackets
            >
              {intl.formatMessage(
                registerConfirmMessages.SESSION__REGISTER_CONFIRM__MAIN_PAGE
              )}
            </LinkButton>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

export default Thanks;
