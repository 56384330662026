export const PREFIXES = {
  APICALL: 'APICALL_',
  REQUEST: '_REQUEST',
  SUCCESS: '_SUCCESS',
  ERROR: '_ERROR'
};

export default {
  APICALL: type => `${PREFIXES.APICALL}${type}`,
  REQUEST: type => `${type}${PREFIXES.REQUEST}`,
  SUCCESS: type => `${type}${PREFIXES.SUCCESS}`,
  ERROR: type => `${type}${PREFIXES.ERROR}`
};
