import produce from 'immer';
import actionType from 'utils/actionType';
import { findIndex, reject } from 'lodash';

import {
  ADMIN_EDIT_PAGE_ADD_ACTION_TO_PAGE,
  ADMIN_EDIT_PAGE_DELETE_ACTION_FROM_PAGE,
  ADMIN_EDIT_PAGE_FETCH_PAGE_ACCESS_DATA,
  ADMIN_EDIT_PAGE_UPDATE_ACTION_IN_PAGE,
  FETCH_WEB_PAGE
} from 'modules/AdminPanel/AdminEditPage/actionType';

const INITIAL_STATE = {
  webPage: null,
  accessData: null,
  statuses: {
    fetchingWebPage: false,
    addingWebPageAction: false,
    updatingWebPageAction: {},
    deletingWebPageAction: {},
    fetchingWebPageAccessData: false
  },
  errors: {
    afterFetchingWebPage: false,
    afterAddingWebPageAction: false,
    afterUpdatingWebPageAction: {},
    afterDeletingWebPageAction: {},
    afterFetchingWebPageAccessData: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(FETCH_WEB_PAGE): {
        state.webPage = INITIAL_STATE.webPage;
        state.errors.afterFetchingWebPage = false;
        state.statuses.fetchingWebPage = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_WEB_PAGE): {
        state.webPage = action.payload.data;
        state.errors.afterFetchingWebPage = false;
        state.statuses.fetchingWebPage = false;

        return state;
      }

      case actionType.ERROR(FETCH_WEB_PAGE): {
        state.errors.afterFetchingWebPage = true;
        state.statuses.fetchingWebPage = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_EDIT_PAGE_UPDATE_ACTION_IN_PAGE): {
        const { idActionWebPageRelation } = action;

        state.errors.afterUpdatingWebPageAction[idActionWebPageRelation] = false;
        state.statuses.updatingWebPageAction[idActionWebPageRelation] = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_PAGE_UPDATE_ACTION_IN_PAGE): {
        const { idActionWebPageRelation, payload } = action;

        const changedActionIndex = findIndex(
          state.webPage.actions,
          action => action.pivot.id === payload.data.id
        );

        if (changedActionIndex >= 0) {
          state.webPage.actions[changedActionIndex].pivot = payload.data;
        }

        state.errors.afterUpdatingWebPageAction[idActionWebPageRelation] = false;
        state.statuses.updatingWebPageAction[idActionWebPageRelation] = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_PAGE_UPDATE_ACTION_IN_PAGE): {
        const { idActionWebPageRelation } = action;

        state.errors.afterUpdatingWebPageAction[idActionWebPageRelation] = true;
        state.statuses.updatingWebPageAction[idActionWebPageRelation] = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_EDIT_PAGE_DELETE_ACTION_FROM_PAGE): {
        const { pageTaskId } = action;

        state.errors.afterDeletingWebPageAction[pageTaskId] = false;
        state.statuses.deletingWebPageAction[pageTaskId] = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_PAGE_DELETE_ACTION_FROM_PAGE): {
        const { pageTaskId } = action;

        state.webPage.actions = reject(state.webPage.actions, action => action.id === pageTaskId);
        state.errors.afterDeletingWebPageAction[pageTaskId] = false;
        state.statuses.deletingWebPageAction[pageTaskId] = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_PAGE_DELETE_ACTION_FROM_PAGE): {
        const { pageTaskId } = action;

        state.errors.afterDeletingWebPageAction[pageTaskId] = true;
        state.statuses.deletingWebPageAction[pageTaskId] = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_EDIT_PAGE_ADD_ACTION_TO_PAGE): {
        state.errors.afterAddingWebPageAction = false;
        state.statuses.addingWebPageAction = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_PAGE_ADD_ACTION_TO_PAGE): {
        state.webPage.actions = [...state.webPage.actions, action.payload.data];

        state.errors.afterAddingWebPageAction = false;
        state.statuses.addingWebPageAction = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_PAGE_ADD_ACTION_TO_PAGE): {
        state.errors.afterAddingWebPageAction = true;
        state.statuses.addingWebPageAction = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_EDIT_PAGE_FETCH_PAGE_ACCESS_DATA): {
        state.errors.afterFetchingWebPageAccessData = false;
        state.statuses.fetchingWebPageAccessData = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_PAGE_FETCH_PAGE_ACCESS_DATA): {
        state.accessData = action.payload.data.access?.data;

        state.errors.afterFetchingWebPageAccessData = false;
        state.statuses.fetchingWebPageAccessData = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_PAGE_FETCH_PAGE_ACCESS_DATA): {
        state.errors.afterFetchingWebPageAccessData = true;
        state.statuses.fetchingWebPageAccessData = false;

        return state;
      }

      default:
        return state;
    }
  });
