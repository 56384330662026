import './link_button.scss';

import React from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Button from 'components/Button/Button';

const propTypes = {
  className: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  href: PropTypes.string,
  disabled: PropTypes.bool,
  fill: PropTypes.bool,
  withBrackets: PropTypes.bool
};

const defaultProps = {
  className: '',
  kind: 'link',
  fill: false,
  withBrackets: false
};

const mainClassName = 'link-button';

const LinkButton = props => {
  const { className, to, children, kind, disabled, fill, href, withBrackets, ...restProps } = props;
  const classes = classnames(
    mainClassName,
    {
      [`${mainClassName}--kind-${kind}`]: kind,
      [`${mainClassName}--disabled`]: disabled,
      fill: !!fill
    },
    className
  );

  if (disabled) {
    return <Button {...props} />;
  }

  if (href) {
    return (
      <a className={classes} href={href} {...restProps}>
        {children}
      </a>
    );
  }

  return (
    <Link className={classes} to={to} {...restProps}>
      {withBrackets && <span>{`<`}</span>}
      {children}
      {withBrackets && <span>{`/>`}</span>}
    </Link>
  );
};

LinkButton.propTypes = propTypes;
LinkButton.defaultProps = defaultProps;

export default LinkButton;
