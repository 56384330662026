import 'modules/Session/ResetPass/reset_password.scss';

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, reduxForm } from 'redux-form';

import BasicLayout from 'components/BasicLayout/BasicLayout';

import Button from 'components/Button/Button';

import InputComponent from 'components/FormComponents/InputComponent';
import {
  resetUserPasswordChangePassword,
  resetUserPasswordCheckToken
} from 'modules/Session/actions';

import { mainPaths } from 'const/paths';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import { getUserEmail } from 'modules/Session/getters';

import { ReactComponent as ChevronCircleLeftIcon } from './circle-left-solid-arrow.svg';

const mainClassName = 'reset-password';

// const mainMessageId = 'session.reset_pass'
class ResetPassToken extends React.Component {
  state = {
    verified: false
  };

  componentDidMount() {
    const {
      match: {
        params: { token }
      }
    } = this.props;

    return this.props
      .resetUserPasswordCheckToken(token)
      .then(() => {
        this.setState({
          verified: true
        });
      })
      .catch(error => {
        this.props.history.push(mainPaths.LOGIN);
        toastr.error('Link resetowania hasła jest nieprawidłowy');
        console.error(error);
      });
  }

  onSubmit = values => {
    const {
      match: {
        params: { token }
      }
    } = this.props;
    const { password } = values;

    return this.props
      .resetUserPasswordChangePassword(token, password)
      .then(() => {
        this.props.history.push(mainPaths.LOGIN);
        toastr.success('Hasło zostało zmienione. Zaloguj się');
      })
      .catch(error => {
        toastr.error('Wprowadzone dane są nieprawidłowe. Spróbuj ponownie');
        console.error(error);
      });
  };

  renderContent() {
    const { verified } = this.state;
    const { handleSubmit, userEmail } = this.props;

    if (!verified) {
      return <CircleLoader height="100%" message="Trwa weryfikacja danych" />;
    }

    return (
      <Form className={`${mainClassName}__form`} onSubmit={handleSubmit(this.onSubmit)}>
        {userEmail && (
          <div>
            <b>E-mail:</b> {userEmail}
          </div>
        )}
        <Field
          hideEmptyError
          name="password"
          component={InputComponent}
          type="password"
          label="Nowe hasło"
        />
        <div className={`${mainClassName}__submit-wrapper`}>
          <Button withBrackets kind="outline" type="submit" className="btn_custom">
            Resetuj hasło
          </Button>
        </div>
      </Form>
    );
  }

  render() {

    return (
      <>
        <Helmet>
          <title>Resetowania hasła</title>
        </Helmet>
        <BasicLayout

        >
          <div className={mainClassName}>
            <h2 className={`${mainClassName}__form-title`}>Zresetuj hasło</h2>
            {this.renderContent()}

            <div className={`${mainClassName}__back-wrapper`}>
              <Link className={`${mainClassName}__back-icon`} to="/login">
                <ChevronCircleLeftIcon height={50} width={50} />
              </Link>
            </div>
          </div>
        </BasicLayout>
      </>
    );
  }
}

const mapStateToProps = state => ({
  userEmail: getUserEmail(state)
});

const mapDispatchToProps = {
  resetUserPasswordCheckToken,
  resetUserPasswordChangePassword
};

const validate = values => {
  const errors = {};

  const { password } = values;

  if (!password) {
    errors.password = 'Podaj hasło do konta';
  }

  return errors;
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'ResetUserPasswordChangePassword',
    validate
  })
)(ResetPassToken);
