export const USER_ADD_NEW_PAGE = 'USER_ADD_NEW_PAGE';
export const USER_PAGE_UPDATE_PAGE = 'USER_PAGE_UPDATE_PAGE';
export const USER_PAGE_GET_PAGE = 'USER_PAGE_GET_PAGE';
export const USER_PAGE_GET_ACCESS_DATA = 'USER_PAGE_GET_ACCESS_DATA';
export const USER_PAGE_UPDATE_ACCESS_DATA = 'USER_PAGE_UPDATE_ACCESS_DATA';

export const USER_PAGE_SET_SUBSCRIPTION_PACKAGE_FOR_PAGE =
  'USER_PAGE_SET_SUBSCRIPTION_PACKAGE_FOR_PAGE';
export const USER_PAGE_UPDATE_SUBSCRIPTION_PACKAGE_FOR_PAGE =
  'USER_PAGE_UPDATE_SUBSCRIPTION_PACKAGE_FOR_PAGE';
export const USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE =
  'USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE';

export const USER_PAGE_GET_PAGE_PAYMENT_INVOICE = 'USER_PAGE_GET_PAGE_PAYMENT_INVOICE';

export const USER_PAGE_DELETE_PAGE_SUBSCRIPTION = 'USER_PAGE_DELETE_PAGE_SUBSCRIPTION';
export const USER_PAGE_DELETE_PAYMENT_CARD = 'USER_PAGE_DELETE_PAYMENT_CARD';
/*
PAYU Payment
 */
export const USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS =
  'USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS';
