import produce from "immer";

import actionType from "utils/actionType";
import {
  USER_PAGE_DELETE_PAGE_SUBSCRIPTION,
  USER_PAGE_DELETE_PAYMENT_CARD,
  USER_PAGE_GET_ACCESS_DATA,
  USER_PAGE_GET_PAGE,
  USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE,
  USER_PAGE_SET_SUBSCRIPTION_PACKAGE_FOR_PAGE,
  USER_PAGE_UPDATE_SUBSCRIPTION_PACKAGE_FOR_PAGE,
  USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS,
} from "modules/User/UserPage/actionType";

const INITIAL_STATE = {
  webPage: null,
  pageAccessData: null,
  pageSubscriptionPackage: null,
  pageSubscriptionPayment: null,
  statuses: {
    fetchingUserWebPage: false,
    fetchingPageAccessData: false,
    fetchingPageSubscriptionPackage: false,
    fetchingPayuPaymentProcess: false,
    deletingPagePaymentCard: false,
  },
  errors: {
    afterFetchingUserWebPage: false,
    afterFetchingPageAccessData: false,
    afterFetchingPageSubscriptionPackage: false,
    AfterFetchingPayuPaymentProcess: false,
    afterDeletingPagePaymentCard: false,
  },
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, (state) => {
    switch (action.type) {
      case actionType.REQUEST(USER_PAGE_GET_PAGE): {
        state.webPage = INITIAL_STATE.webPage;
        state.errors.afterFetchingUserWebPage = false;
        state.statuses.fetchingUserWebPage = true;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_GET_PAGE): {
        state.webPage = action.payload.data;

        state.errors.afterFetchingUserWebPage = false;
        state.statuses.fetchingUserWebPage = false;

        return state;
      }

      case actionType.ERROR(USER_PAGE_GET_PAGE): {
        state.errors.afterFetchingUserWebPage = true;
        state.statuses.fetchingUserWebPage = false;

        return state;
      }

      case actionType.REQUEST(USER_PAGE_GET_ACCESS_DATA): {
        state.pageAccessData = INITIAL_STATE.pageAccessData;
        state.errors.afterFetchingPageAccessData = false;
        state.statuses.fetchingPageAccessData = true;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_GET_ACCESS_DATA): {
        state.pageAccessData = action.payload.data.access?.data;

        state.errors.afterFetchingPageAccessData = false;
        state.statuses.fetchingPageAccessData = false;

        return state;
      }

      case actionType.ERROR(USER_PAGE_GET_ACCESS_DATA): {
        state.errors.afterFetchingPageAccessData = true;
        state.statuses.fetchingPageAccessData = false;

        return state;
      }

      case actionType.REQUEST(USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE): {
        state.pageSubscriptionPackage = INITIAL_STATE.pageSubscriptionPackage;
        state.pageSubscriptionPayment = INITIAL_STATE.pageSubscriptionPayment;

        state.errors.afterFetchingPageSubscriptionPackage = false;
        state.statuses.fetchingPageSubscriptionPackage = true;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE): {
        state.pageSubscriptionPackage =
          action.payload.data.page.subscription_package;
        state.pageSubscriptionPayment = action.payload.data.payment;

        state.errors.afterFetchingPageSubscriptionPackage = false;
        state.statuses.fetchingPageSubscriptionPackage = false;

        return state;
      }

      case actionType.ERROR(USER_PAGE_GET_SUBSCRIPTION_PACKAGE_FOR_PAGE): {
        state.errors.afterFetchingPageSubscriptionPackage = true;
        state.statuses.fetchingPageSubscriptionPackage = false;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_SET_SUBSCRIPTION_PACKAGE_FOR_PAGE): {
        state.pageSubscriptionPackage = {
          ...action.payload.data.page.subscription_package,
          package: action.subscriptionPackage,
        };

        state.webPage.subscription_package = {
          ...action.payload.data.page.subscription_package,
          package: action.subscriptionPackage,
        };

        state.pageSubscriptionPayment = action.payload.data.payment;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_UPDATE_SUBSCRIPTION_PACKAGE_FOR_PAGE): {
        state.pageSubscriptionPackage = {
          ...action.payload.data.page.subscription_package,
          next_package: action.subscriptionPackage,
        };

        if (
          action.subscriptionPackage?.id ===
          state.webPage.subscription_package?.package?.id
        ) {
          state.webPage.subscription_package = {
            ...state.webPage.subscription_package,
            ...action.payload.data.page.subscription_package,
            package: action.subscriptionPackage,
            next_package: null,
          };
        } else {
          state.webPage.subscription_package = {
            ...state.webPage.subscription_package,
            ...action.payload.data.page.subscription_package,
            next_package: action.subscriptionPackage,
          };
        }

        state.pageSubscriptionPayment = action.payload.data.payment;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_DELETE_PAGE_SUBSCRIPTION): {
        state.webPage.subscription_package = {
          ...state.webPage.subscription_package,
          ...action.payload.data.package,
        };
        state.pageSubscriptionPackage = {
          ...state.pageSubscriptionPackage,
          ...action.payload.data.package,
        };

        return state;
      }

      case actionType.REQUEST(USER_PAGE_DELETE_PAYMENT_CARD): {
        state.statuses.deletingPagePaymentCard = true;
        state.errors.afterdeletingPagePaymentCard = false;

        return state;
      }

      case actionType.SUCCESS(USER_PAGE_DELETE_PAYMENT_CARD): {
        state.statuses.deletingPagePaymentCard = false;
        state.errors.afterdeletingPagePaymentCard = false;

        state.webPage.subscription_package.card = null;
        state.pageSubscriptionPackage.card = null;

        return state;
      }

      case actionType.ERROR(USER_PAGE_DELETE_PAYMENT_CARD): {
        state.statuses.deletingPagePaymentCard = false;
        state.errors.afterdeletingPagePaymentCard = true;

        return state;
      }

      case actionType.REQUEST(
        USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS
      ): {
        state.statuses.fetchingPayuPaymentProcess = true;
        state.errors.afterFetchingPayuPaymentProcess = false;

        return state;
      }

      case actionType.SUCCESS(
        USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS
      ): {
        state.statuses.fetchingPayuPaymentProcess = false;
        state.errors.afterFetchingPayuPaymentProcess = false;

        return state;
      }

      case actionType.ERROR(
        USER_PAGE_SUBSCRIPTION_PACKAGE_PAYU_PAYMENT_PROCESS
      ): {
        state.statuses.fetchingPayuPaymentProcess = false;
        state.errors.afterFetchingPayuPaymentProcess = true;

        return state;
      }

      default:
        return state;
    }
  });
