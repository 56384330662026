import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classnames from 'classnames';
import TechnicalMessage from 'libs/ui/TechnicalMessage/TechnicalMessage';
import ComponentWrapper from 'modules/DevTools/ComponentWrapper';
import { Link } from 'react-router-dom';

export default class DevTools extends Component {
  static propTypes = {
    className: PropTypes.string
  };

  static defaultProps = {
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { className } = this.props;

    const classes = classnames(className);

    return (
      <div className={classes}>
        <h1>Dev tools</h1>

        <ComponentWrapper name="TechnicalMessage">
          <TechnicalMessage
            onClose={() => {}}
            title="Uzupełnij dane strony"
            content={
              <>
                <div>
                  Nie uzupełniłeś wszystkich danych strony. Usługa nie będzie działać do momentu ich
                  uzupełnienia.
                </div>
                <div>
                  Aby to zrobić przejdź do <Link to="/user/pages">edycji danych strony</Link>
                </div>
              </>
            }
          />
        </ComponentWrapper>
        <ComponentWrapper name="TechnicalMessage kind=dark">
          <TechnicalMessage
            onClose={() => {}}
            kind="dark"
            title="Uzupełnij dane strony"
            content={
              <>
                <div>
                  Nie uzupełniłeś wszystkich danych strony. Usługa nie będzie działać do momentu ich
                  uzupełnienia.
                </div>
                <div>
                  Aby to zrobić przejdź do <Link to="/user/pages">edycji danych strony</Link>
                </div>
              </>
            }
          />
        </ComponentWrapper>
      </div>
    );
  }
}
