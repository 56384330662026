import { useCallback } from 'react';

import { useIntl } from 'react-intl';
import { compact } from 'lodash';

export const useFieldValidate = (validations, deps) => {
  const intl = useIntl();

  return useCallback(
    (value, allValues, props) => {
      return compact(
        validations.map(validationFunc => {
          const resultMessage = validationFunc(value, allValues, props);

          if (!resultMessage) return resultMessage;

          if (resultMessage.message) {
            return intl.formatMessage(resultMessage.message, resultMessage.values);
          }

          if (resultMessage.id) {
            return intl.formatMessage(resultMessage);
          }

          return resultMessage;
        })
      )?.[0];
    },

    // eslint-disable-next-line
    deps ? [...validations, ...deps] : [...validations]
  );
};

export default useFieldValidate;
