import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import sessionReducer from 'modules/Session/reducer';

import adminActionsReducer from 'modules/AdminPanel/AdminTaskTemplates/reducer';
import adminWebPagesListReducer from 'modules/AdminPanel/AdminWebPages/reducer';
import adminEditPageReducer from 'modules/AdminPanel/AdminEditPage/reducer';
import adminEditUserReducer from 'modules/AdminPanel/AdminEditUser/reducer';
import adminUsersReducer from 'modules/AdminPanel/AdminUsers/reducer';
import userPagesReducer from 'modules/User/UserPages/userPagesReducer';
import userPageReducer from 'modules/User/UserPage/userPageReducer';
import userPaymentsReducer from 'modules/User/UserPayments/userPaymentsReducer';
import userPageTasksReducer from 'modules/User/UserPageTasks/userPageTasksReducer';
import userHomeReducer from 'modules/User/UserHome/userHomeReducer';

export default (injectedReducers = {}) =>
  combineReducers({
    toastr: toastrReducer,
    form: formReducer,
    ...injectedReducers,
    session: sessionReducer,
    adminActions: adminActionsReducer,
    adminWebPages: adminWebPagesListReducer,
    adminEditPage: adminEditPageReducer,
    adminEditUser: adminEditUserReducer,
    adminUsers: adminUsersReducer,

    userPages: userPagesReducer,
    userPage: userPageReducer,
    userPayments: userPaymentsReducer,
    userPageTasks: userPageTasksReducer,
    userHome: userHomeReducer
  });
