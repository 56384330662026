import produce from "immer";
import {
  AUTHORIZE_USER,
  AUTHORIZE_USER_WITH_TOKEN,
  LOGOUT,
  RESEND_VERIFICATION_EMAIL,
  RESET_USER_PASSWORD_CHECK_TOKEN,
  VERIFICATION_EMAIL,
} from "modules/Session/actionType";
import LocalStorageManagement from "modules/Session/LocalStorageManagement";
import actionType from "utils/actionType";

import { USER_SETTINGS_UPDATE_USER_NOTIFICATIONS } from "modules/User/UserSettings/actionType";

const INITIAL_STATE = {
  auth: {
    token: LocalStorageManagement.getUserSessionToken(),
    role: "",
    name: "",
  },
  isAuthorized: false,
  statuses: {
    fetchingAuthorize: false,
    fetchingAuthorizeByToken: false,
    resendVerificationEmailAddress: false,
  },
  errors: {
    afterFetchingAuthorize: false,
    afterFetchingAuthorizeByToken: false,
    afterResendVerificationEmailAddress: false,
  },
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, (state) => {
    switch (action.type) {
      /**
       * Authorize user
       */
      case actionType.REQUEST(AUTHORIZE_USER): {
        state.auth = { ...INITIAL_STATE.auth };
        state.isAuthorized = false;

        state.statuses.fetchingAuthorize = true;
        state.errors.afterFetchingAuthorize = false;

        return state;
      }

      case actionType.SUCCESS(AUTHORIZE_USER): {
        const { token } = action.payload.data;
        const { rememberToken } = action.data;

        rememberToken && LocalStorageManagement.setUserSessionToken(token);

        //TODO Verify API when add data
        state.auth = action.payload.data;

        state.isAuthorized = true;

        state.statuses.fetchingAuthorize = false;
        state.errors.afterFetchingAuthorize = false;

        return state;
      }

      case actionType.ERROR(AUTHORIZE_USER): {
        state.statuses.fetchingAuthorize = false;
        state.errors.afterFetchingAuthorize = true;

        return state;
      }

      /**
       * Authorize user by token
       */
      case actionType.REQUEST(AUTHORIZE_USER_WITH_TOKEN): {
        state.isAuthorized = false;

        state.statuses.fetchingAuthorizeByToken = true;
        state.errors.afterFetchingAuthorizeByToken = false;

        return state;
      }

      case actionType.SUCCESS(AUTHORIZE_USER_WITH_TOKEN): {
        state.auth = {
          ...(state.auth || {}),
          ...action.payload.data,
        };

        state.isAuthorized = true;

        state.statuses.fetchingAuthorizeByToken = false;
        state.errors.afterFetchingAuthorizeByToken = false;

        return state;
      }

      case actionType.ERROR(AUTHORIZE_USER_WITH_TOKEN): {
        state.auth = { ...INITIAL_STATE.auth };
        state.isAuthorized = false;

        state.statuses.fetchingAuthorizeByToken = false;
        state.errors.afterFetchingAuthorizeByToken = true;

        return state;
      }

      /**
       * Logout user
       */

      case LOGOUT: {
        state = { ...INITIAL_STATE, auth: {} };

        LocalStorageManagement.removeUserSessionToken();

        return state;
      }

      case actionType.SUCCESS(RESET_USER_PASSWORD_CHECK_TOKEN): {
        state.auth = { email: action.payload.email };
        return state;
      }

      /**
       * Authorize user by token
       */
      case actionType.REQUEST(RESEND_VERIFICATION_EMAIL): {
        state.statuses.resendVerificationEmailAddress = true;
        state.errors.afterResendVerificationEmailAddress = false;

        return state;
      }

      case actionType.SUCCESS(RESEND_VERIFICATION_EMAIL): {
        state.statuses.resendVerificationEmailAddress = false;
        state.errors.afterResendVerificationEmailAddress = false;

        return state;
      }

      case actionType.ERROR(RESEND_VERIFICATION_EMAIL): {
        state.statuses.resendVerificationEmailAddress = false;
        state.errors.afterResendVerificationEmailAddress = true;

        return state;
      }

      case actionType.SUCCESS(VERIFICATION_EMAIL): {
        state.auth.email_verified_at = new Date();
        return state;
      }

      case actionType.SUCCESS(USER_SETTINGS_UPDATE_USER_NOTIFICATIONS): {
        const {
          notify_settings,
          notify_db,
          notify_email,
        } = action.payload.data;
        state.auth.notify_settings = notify_settings;
        state.auth.notify_db = notify_db;
        state.auth.notify_email = notify_email;

        return state;
      }

      default: {
        return state;
      }
    }
  });
