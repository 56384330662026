import produce from 'immer';
import { pick } from 'lodash';

import actionType from 'utils/actionType';
import {
  FETCH_USER_PAYMENTS_INVOICE_DATA,
  USER_PAYMENTS_FETCH_USER_SUBSCRIPTION_PACKAGES,
  USER_PAYMENTS_GET_PAYMENTS_HISTORY,
  USER_PAYMENTS_GET_PAGE_PAYMENT_INVOICE
} from 'modules/User/UserPayments/actionType';

const INITIAL_STATE = {
  subscriptionPackages: null,
  invoiceData: null,
  paymentsHistory: null,
  paymentsHistoryStats: {
    from: 0,
    per_page: 10,
    to: 0,
    total: 0,
    current: 0
  },
  statuses: {
    fetchingSubscriptionPackages: false,
    fetchingPaymentsInvoiceData: false,
    fetchingPaymentsHistory: false,
    fetchingInvoice: {}
  },
  errors: {
    afterFetchingSubscriptionPackages: false,
    afterFetchingPaymentsInvoiceData: false,
    afterFetchingPaymentsHistory: false,
    afterFetchingInovice: {}
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(USER_PAYMENTS_FETCH_USER_SUBSCRIPTION_PACKAGES): {
        state.subscriptionPackages = INITIAL_STATE.subscriptionPackages;
        state.errors.afterFetchingSubscriptionPackages = false;
        state.statuses.fetchingSubscriptionPackages = true;

        return state;
      }

      case actionType.SUCCESS(USER_PAYMENTS_FETCH_USER_SUBSCRIPTION_PACKAGES): {
        state.subscriptionPackages = action.payload.data.packages;

        state.errors.afterFetchingSubscriptionPackages = false;
        state.statuses.fetchingSubscriptionPackages = false;

        return state;
      }

      case actionType.ERROR(USER_PAYMENTS_FETCH_USER_SUBSCRIPTION_PACKAGES): {
        state.errors.afterFetchingSubscriptionPackages = true;
        state.statuses.fetchingSubscriptionPackages = false;

        return state;
      }

      case actionType.REQUEST(FETCH_USER_PAYMENTS_INVOICE_DATA): {
        state.invoiceData = INITIAL_STATE.invoiceData;
        state.errors.afterFetchingPaymentsInvoiceData = false;
        state.statuses.fetchingPaymentsInvoiceData = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_USER_PAYMENTS_INVOICE_DATA): {
        state.invoiceData = action.payload.data;

        state.errors.afterFetchingPaymentsInvoiceData = false;
        state.statuses.fetchingPaymentsInvoiceData = false;

        return state;
      }

      case actionType.ERROR(FETCH_USER_PAYMENTS_INVOICE_DATA): {
        state.errors.afterFetchingPaymentsInvoiceData = true;
        state.statuses.fetchingPaymentsInvoiceData = false;

        return state;
      }

      case actionType.REQUEST(USER_PAYMENTS_GET_PAYMENTS_HISTORY): {
        state.statuses.fetchingPaymentsHistory = true;
        state.errors.afterFetchingPaymentsHistory = false;

        state.paymentsHistory = INITIAL_STATE.paymentsHistory;
        state.paymentsHistoryStats = INITIAL_STATE.paymentsHistoryStats;

        return state;
      }
      case actionType.SUCCESS(USER_PAYMENTS_GET_PAYMENTS_HISTORY): {
        state.statuses.fetchingPaymentsHistory = false;
        state.errors.afterFetchingPaymentsHistory = false;

        state.paymentsHistory = action.payload.data.data;
        state.paymentsHistoryStats = pick(action.payload.data, [
          'from',
          'per_page',
          'to',
          'total',
          'current'
        ]);

        return state;
      }

      case actionType.ERROR(USER_PAYMENTS_GET_PAYMENTS_HISTORY): {
        state.statuses.fetchingPaymentsHistory = false;
        state.errors.afterFetchingPaymentsHistory = true;

        return state;
      }

      case actionType.REQUEST(USER_PAYMENTS_GET_PAGE_PAYMENT_INVOICE): {
        state.statuses.fetchingInvoice[action.data.paymentId] = true;
        state.errors.afterFetchingInovice[action.data.paymentId] = false;

        return state;
      }
      case actionType.SUCCESS(USER_PAYMENTS_GET_PAGE_PAYMENT_INVOICE): {
        state.statuses.fetchingInvoice[action.data.paymentId] = false;
        state.errors.afterFetchingInovice[action.data.paymentId] = false;

        return state;
      }

      case actionType.ERROR(USER_PAYMENTS_GET_PAGE_PAYMENT_INVOICE): {
        state.statuses.fetchingInvoice[action.data.paymentId] = false;
        state.errors.afterFetchingInovice[action.data.paymentId] = true;

        return state;
      }

      default:
        return state;
    }
  });
