import { defineMessages } from "react-intl";

const messages = defineMessages({
  FORMS__VALIDATION__INPUT_REQUIRED: {
    id: "forms.validation.input_is_required",
    defaultMessage: "Pole jest wymagane",
  },
  FORMS__VALIDATION__INPUT_MAX_LENGTH: {
    id: "forms.validation.input_max_length",
    defaultMessage: `Maksymalnie {max} znaków lub mniej`,
  },
  FORMS__VALIDATION__INPUT_MIN_LENGTH: {
    id: "forms.validation.input_min_length",
    defaultMessage: `Wymagane jest minimalnie {min} znaków lub więcej`,
  },
  FORMS__VALIDATION__INPUT_NUMBER: {
    id: "forms.validation.input_is_number",
    defaultMessage: "Wpisana wartość pola musi być liczbą",
  },
  FORMS__VALIDATION__INPUT_MIN_VALUE: {
    id: "forms.validation.input_min_value",
    defaultMessage: "Wymagane jest {min} znaków",
  },
  FORMS__VALIDATION__INPUT_EMAIL: {
    id: "forms.validation.input_email",
    defaultMessage: "Nieprawidłowy adres email",
  },
  FORMS__VALIDATION__INPUT_POSTAL_CODE: {
    id: "forms.validation.input_postal_code",
    defaultMessage: "Nieprawidłowy kod pocztowy",
  },
  FORMS__VALIDATION__NIP: {
    id: "forms.validation.input_nip",
    defaultMessage: "Nieprawidłowy nip",
  },
  FORMS__VALIDATION__INPUT_ALPHANUMERIC: {
    id: "forms.validation.input_alphanumeric",
    defaultMessage: "W polu mogą być tylko znaki alfanumeryczne",
  },
  FORMS__VALIDATION__ARRAY_MIN_SIZE: {
    id: "forms.validation.array_min_size",
    defaultMessage: "Wymagane jest {min} elementów",
  },
  FORMS__VALIDATION__USER_PASSWORD: {
    id: "forms.validation.user_password",
    defaultMessage:
      "Hasło musi być bezpieczne - minimum 8 znaków, w tym 1 duża litera, 1 mała litera i jedna cyfra",
  },
});

export default messages;
