import './checkbox.scss';

import React from 'react';
import classnames from 'classnames';

const mainClassName = 'checkbox';
const Checkbox = ({ label, value, name, hideEmptyError, error, ...restProps }) => {
  return (
    <div className={mainClassName}>
      <label className="checkbox_wrapper with_margin">
        <input type="checkbox" name={name} checked={value} {...restProps} />
        <span>{label}</span>
      </label>
      {(!hideEmptyError || error) && (
        <div
          className={classnames(
            `${mainClassName}__error-field`,
            `${mainClassName}__error-field--filled`
          )}
        >
          {error || ''}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
