import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { authorizeWithToken } from "modules/Session/actions";
import {
  getSessionStatus,
  getUserData,
  getUserIsAuthorized,
  getUserRole,
  getUserToken,
} from "modules/Session/getters";
import CircleLoader from "components/CircleLoader/CircleLoader";
import sessionMessages from "modules/Session/sessionMessages";
import { useIntl } from "react-intl";

const Session = ({ children }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const location = useLocation();

  const [initialized, setInitialized] = useState(false);

  const hasToken = useSelector((state) => getUserToken(state));
  const isAuthorized = useSelector((state) => getUserIsAuthorized(state));
  const userRole = useSelector((state) => getUserRole(state));
  const userData = useSelector((state) => getUserData(state));
  const isAuthorizing = useSelector((state) =>
    getSessionStatus(state, "fetchingAuthorizeByToken")
  );

  const onAuthorizeFailure = () => {
    setInitialized(true);
  };

  const tryAuthorizeWithToken = () => {
    return dispatch(authorizeWithToken()).then(() => {
      setInitialized(true);
    });
  };

  useEffect(() => {
    if (hasToken) {
      if (!isAuthorized) {
        tryAuthorizeWithToken().catch(onAuthorizeFailure);
      } else {
        setInitialized(true);
      }
    } else {
      onAuthorizeFailure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasToken]);

  if (isAuthorizing) {
    return (
      <CircleLoader
        message={intl.formatMessage(
          sessionMessages.SESSION__USER_VERIFICATION_IS_IN_PROGRESS
        )}
        height="100vh"
        margin="0"
      />
    );
  }

  if (!initialized) {
    return null;
  }

  return children({ userRole, isAuthorized, userData, location });
};

export default Session;
