import produce from 'immer';
import { pick } from 'lodash';

import actionType from 'utils/actionType';
import { FETCH_USER_PAGES } from 'modules/User/UserPages/actionType';

const INITIAL_STATE = {
  pages: null,
  stats: {
    from: 0,
    per_page: 10,
    to: 0,
    total: 0,
    current: 0
  },
  statuses: {
    fetchingUserPages: false
  },
  errors: {
    afterFetchingUserPages: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(FETCH_USER_PAGES): {
        state.pages = INITIAL_STATE.pages;
        state.stats = INITIAL_STATE.stats;
        state.errors.afterFetchingUserPages = false;
        state.statuses.fetchingUserPages = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_USER_PAGES): {
        state.pages = action.payload.data.data;
        state.stats = pick(action.payload.data, [
          'from',
          'to',
          'per_page',
          'total',
          'current_page'
        ]);

        state.errors.afterFetchingUserPages = false;
        state.statuses.fetchingUserPages = false;

        return state;
      }

      case actionType.ERROR(FETCH_USER_PAGES): {
        state.errors.afterFetchingUserPages = true;
        state.statuses.fetchingUserPages = false;

        return state;
      }

      default:
        return state;
    }
  });
