import React from 'react';
import { mainPaths } from 'const/paths';
import Session from 'modules/Session/Session';
import Helmet from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import ReactModal from 'react-modal';

import { commonRoutes, onlyPublicRoutes, privateRouteByUserRole, privateRoutes } from './routes';
import DevTools from 'modules/DevTools/DevTools';

ReactModal.setAppElement('body');

const DEVELOPMENT_MODE = process.env.NODE_ENV === 'development';

const App = () => {
  return (
    <div className="app">
      <Helmet titleTemplate="%s | ForSecure" defaultTitle="ForSecure" />
      <Session>
        {({ isAuthorized, userRole, location }) => {
          if (!isAuthorized) {
            return (
              <Switch>
                {[...commonRoutes, ...onlyPublicRoutes]?.map((props, index) => (
                  <Route key={index} {...props} />
                ))}
                {DEVELOPMENT_MODE && <Route component={DevTools} path="/dev" />}
                <Redirect
                  to={{
                    pathname: mainPaths.LOGIN,
                    state: {
                      destinationLocation: location
                    }
                  }}
                />
              </Switch>
            );
          } else {
            const route = privateRouteByUserRole[userRole];

            if (!route) {
              //TODO handle strange user role
              return <div>Rola użytkownika nie jest obsługiwana w aplikacji.</div>;
            }

            if (location?.state?.destinationLocation?.pathname?.indexOf?.(route.path) === 0) {
              return <Redirect to={location.state.destinationLocation} />;
            }

            return (
              <Switch>
                {[...commonRoutes, ...privateRoutes]?.map((props, index) => (
                  <Route key={index} {...props} />
                ))}
                <Route path={route.path} component={route.component} />
                {DEVELOPMENT_MODE && <Route component={DevTools} path="/dev" />}
                <Redirect to={route.path} />
              </Switch>
            );
          }
        }}
      </Session>
    </div>
  );
};

export default App;
