import actionType from 'utils/actionType';
import { config } from '../config';

export const createApiCall = (type, url, options = {}, data) => {
  const defaults = {
    method: 'GET',
    mode: 'cors'
  };

  options = {
    ...defaults,
    ...options,
    url: url.startsWith('/') ? (options.url = config.apiHost + url) : url
  };

  return {
    type,
    options,
    data
  };
};

function hasOwnPropertyCaseInsensitive(obj, property) {
  const props = [];
  for (var i in obj) if (obj.hasOwnProperty(i)) props.push(i);
  let prop;
  while ((prop = props.pop())) if (prop.toLowerCase() === property.toLowerCase()) return true;
  return false;
}

export const createGetApiThunk = (type, url, options = {}, data) => {
  options.method = 'GET';
  options.headers = options.headers || {};

  return dispatch => {
    return dispatch(createApiCall(actionType.APICALL(type), url, options, data));
  };
};

export const createDeleteApiThunk = (type, url, options = {}, data) => {
  options.method = 'DELETE';
  options.headers = options.headers || {};

  return dispatch => {
    return dispatch(createApiCall(actionType.APICALL(type), url, options, data));
  };
};

export const createPostApiThunk = (type, url, options = {}, data) => {
  options.method = 'POST';
  options.headers = options.headers || {};

  if (
    !options.withoutContentType &&
    !hasOwnPropertyCaseInsensitive(options.headers, 'Content-Type')
  ) {
    options.headers['Content-Type'] = 'application/json';
  }
  return dispatch => {
    return dispatch(createApiCall(actionType.APICALL(type), url, options, data));
  };
};

export const createPutApiThunk = (type, url, options = {}, data) => {
  options.method = 'PUT';
  options.headers = options.headers || {};

  if (!hasOwnPropertyCaseInsensitive(options.headers, 'Content-Type')) {
    options.headers['Content-Type'] = 'application/json';
  }
  return dispatch => {
    return dispatch(createApiCall(actionType.APICALL(type), url, options, data));
  };
};

export const createPatchApiThunk = (type, url, options = {}, data) => {
  options.method = 'PATCH';
  options.headers = options.headers || {};

  if (!hasOwnPropertyCaseInsensitive(options.headers, 'Content-Type')) {
    options.headers['Content-Type'] = 'application/json';
  }
  return dispatch => {
    return dispatch(createApiCall(actionType.APICALL(type), url, options, data));
  };
};

export default {
  get: createGetApiThunk,
  post: createPostApiThunk,
  put: createPutApiThunk,
  patch: createPatchApiThunk,
  delete: createDeleteApiThunk
};
