import React from 'react';
import Checkbox from 'components/Checkbox/Checkbox';

const CheckboxFormComponent = ({
  input,
  meta: { touched, error },
  label,
  className,
  hideEmptyError = false
}) => {
  const { value, ...inputProps } = input;
  return (
    <Checkbox
      className={className}
      label={label}
      value={value}
      error={!!touched && error}
      hideEmptyError={hideEmptyError}
      {...inputProps}
    />
  );
};

export default CheckboxFormComponent;
