import 'components/TopBarNav/top_bar_nav.scss';

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { mainPaths } from 'const/paths';
import { Link, withRouter } from 'react-router-dom';

import { ReactComponent as PowerOffIcon } from 'icons/sign-out.svg';

const mainClassName = 'top-bar-nav';

const defaultProps = {
  isAuthorized: false,
  homeUrl: null
};

const propTypes = {
  isAuthorized: PropTypes.bool,
  homeUrl: PropTypes.string,
  userData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string
  })
};

const TopBarNav = ({ className, isAuthorized, homeUrl, userData }) => {
  const classes = classnames(mainClassName, className);

  return (
    <nav className={classes}>
      <div className={`${mainClassName}__user-email`}>{userData?.email}</div>
      {isAuthorized && (
        <Link className={`${mainClassName}__logout-button`} to={mainPaths.LOGOUT}>
          <PowerOffIcon width={24} height={24} />
        </Link>
      )}
    </nav>
  );
};

TopBarNav.defaultProps = defaultProps;
TopBarNav.propTypes = propTypes;

export default withRouter(TopBarNav);
