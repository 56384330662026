import './page-privacy.scss';

import React from 'react';
import { BasicLayout } from '../../../components';

export default function PrivacyPolicy() {
  return (
    <BasicLayout>
      <section className="page_privacy">
        <h1>Umowa w zakresie administrowania i serwisowania strony www</h1>
        <div className="doc_wrapper">
          <div className="doc_wrapper-inner">
            <div className="row">
              <div className="col-12">
                <strong>Formularz Umowy</strong>
              </div>
            </div>
            <div className="row">
              <div className="col-2">Wykonawca</div>
              <div className="col-10">
                Agencja Interaktywna ADream sp. z o.o. <br />
                Ul. Chopina 18/3a <br />
                30-047 Kraków <br />
                KRS: 0000517132 NIP: 6762477695, REGON: 12316348
              </div>
            </div>
            <div className="row">
              <div className="col-2">Postanowienia podstawowe:</div>
              <div className="col-10">
                Przedmiotem niniejszej Umowy jest administrowanie i zabezpieczania Serwisu
                Internetowego Zamawiającego na warunkach określonych w niniejszym Formularzu Umowy,
                Regulaminie Umowy Abonenckiej, oraz Umowie w zakresie przetwarzania danych osobowych
                dołączonych do oferty - przy czym Zamawiający oświadcza, że zapoznał się z ich
                treścią i je zaakceptował.
                <ul>
                  <li>Audyt początkowy</li>
                  <li>tak</li>
                  <li>Backup strony</li>
                  <li>tak</li>
                  <li>Backup bazy danych</li>
                  <li>tak</li>
                  <li>Dedykowana powierzchnia na backup</li>
                  <li>tak</li>
                  <li>Skaner antywirusowy</li>
                  <li>tak</li>
                  <li>Regularne aktualizacje silnika CMS, motywów i wtyczek</li>
                  <li>tak</li>
                  <li>Funkcja szybkiego przywrócenia serwis</li>
                  <li>tak</li>
                  <li>Pomoc techniczna</li>
                  <li>tak</li>
                  <li>Google Safe Browsing co 24 godziny</li>
                  <li>tak</li>
                  <li>Zarządzanie treścią</li>
                  <li>tak</li>
                  <li>Monitoring aktywności logów</li>
                  <li>tak</li>
                  <li>Monitoring uszkodzonych linków</li>
                  <li>tak</li>
                  <li>Monitoring działania strony</li>
                  <li>tak</li>
                  <li>Monitoring SSL</li>
                  <li>tak</li>
                  <li>Instancja testowa strony</li>
                  <li>tak</li>
                  <li>Optymalizacja szybkości działania strony</li>
                  <li>tak</li>
                  <li>Wprowadzanie dodatkowych zmian / rozwój strony na życzenia</li>
                  <li>tak</li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-7">Wynagrodzenie miesięczne</div>
              <div className="col-5">
                Pakiet Podstawowy - 299 zł netto <br />
                Pakiet Premium – 599 zł netto <br />
                Pakiet E-Commerce – stawka indywidualna
              </div>
            </div>
            <div className="row">
              <div className="col-7">Ilość godzin w ramach abonamentu</div>
              <div className="col-5">
                Pakiet Podstawowy – 1h <br />
                Pakiet Premium – 3h
              </div>
            </div>
          </div>
        </div>
        <h1>Regulamin zawarcia umowy abonenckiej</h1>
        <h3>§ 1. [Przedmiot Umowy]</h3>
        <ul>
          <li>
            Przedmiotem Umowy jest stała obsługa i serwisowanie Serwisu Internetowego Zamawiającego.
            Wykonawca będzie cyklicznie wykonywał czynności określone w Formularzu Umowy, a także
            podejmował działania w przypadku wystąpienia określonych okoliczności zewnętrznych
            („Utrzymanie”).
          </li>
        </ul>
        <h3>§ 2. [Terminy realizacji usługi]</h3>
        <ul>
          <li>
            Jeżeli Formularz Umowy przewiduje wykonywanie Aktualizacji oprogramowania, to będą one
            wykonywane w ciągu 3 Dni Roboczych od wystąpienia potrzeby aktualizacji.
          </li>
          <li>
            Jeżeli Formularz Umowy przewiduje wykonywanie Kopii zapasowych, to będą one wykonywane z
            częstotliwością określoną w Formularzu Umowy.
          </li>
          <li>
            Jeżeli Formularz Umowy przewiduje wykonywanie Kopii zapasowych, to będą one wykonywane z
            częstotliwością określoną w Formularzu Umowy.
          </li>
          <li>
            Jeżeli Formularz Umowy przewiduje świadczenie Monitoringu, to w razie wystąpienia
            problemów z dostępnością Serwisu Internetowego, Wykonawca podejmie działania zmierzające
            do przywrócenia dostępności Strony Internetowe w ramach dostępnych możliwości oraz
            skontaktuje się z dostawcą Serwera Serwisu Internetowego w czasie reakcji określonym w
            Formularzu Umowy.
          </li>
          <li>
            Jeżeli Formularz Umowy przewiduje wykonanie Audytu początkowego, Wykonawca wykona go
            przed przystąpieniem do świadczenia Utrzymania.
          </li>
          <li>
            W przypadku zawarcia Umowy na Wykonanie Serwisu Internetowego Wykonawca rozpocznie
            świadczenie usług w zakresie Utrzymania Serwisu Internetowego po podpisaniu Protokołu
            Odbioru.
          </li>
        </ul>
        <h3>§ 3. [Ogólne zasady realizacji Umowy]</h3>
        <ul>
          <li>
            Strony oświadczają, że – ze względu na charakter projektów informatycznych – są
            świadome, że realizacja Umowy wymaga ich bieżącej współpracy i współdziałania.
          </li>
          <li>
            Współdziałanie Zamawiającego polega również na przekazaniu odpowiednich danych
            dostępowych do Serwisu Internetowego i usług z nim powiązanych – wyszczególnionych w
            rozdziałach dotyczących konkretnych rodzajów Umów – w taki sposób, aby Wykonawca mógł
            wykonywać swoje prace zdalnie.
          </li>
          <li>
            Umowę szczegółową zawiera się w formie ustnej lub pisemnej na Formularzu Umowy. Jeśli
            umowa zawarta jest w formie ustnej potwierdzeniem zawarcia umowy jest Formularz Umowy
            przesłany Zamawiającemu przez Wykonawcę drogą mailową. Formularz Umowy wraz z
            ewentualnymi Załącznikami zawiera kluczowe postanowienia Umowy, które uzupełnione
            treścią Regulaminu tworzą pełną treść Umowy.
          </li>
          <li>
            W celu sprawnej realizacji Umowy, każda ze Stron wyznaczy osobę odpowiedzialną za
            współpracę oraz umocowaną do składania i przyjmowania oświadczeń w zakresie określonym w
            niniejszej Umowie (Koordynatorzy), zgodnie z Formularzem Umowy. Każda ze Stron upoważnia
            wyznaczonego przez siebie Koordynatora do reprezentowania Strony w trakcie realizacji
            Umowy, w tym do składania w imieniu Strony wszelkich oświadczeń związanych z realizacją
            Umowy. Na podstawie Umowy, Koordynatorzy nie są uprawnieni do innych zmian Umowy niż
            wspomniane powyżej ani do składania oświadczeń o odstąpieniu od Umowy.
          </li>
          <li>
            Zmiana Koordynatora powinna zostać dokona poprzez wysłanie maila na Email Kontaktowy
            drugiej Strony ze skutkiem na dzień doręczenia informacji o zmianie drugiej Stronie.
            Zmiana taka nie stanowi zmiany Umowy.
          </li>
          <li>
            Wykonawca może powierzyć wykonywanie swoich obowiązków wynikających z Umowy
            podwykonawcom. Wykonawca ponosi odpowiedzialność za działania i zaniechania
            podwykonawców jak za działania własne. Wykonawca zobowiązuje się do zapewnienia nadzoru
            i koordynacji wszelkich działań swoich pracowników i podwykonawców, związanych z
            realizacją prac objętych przedmiotem Umowy.
          </li>
        </ul>
        <h3>§ 4. [Obowiązki zamawiającego]</h3>
        <ul>
          <li>
            Za wykonanie przedmiotu Umowy Zamawiający zapłaci Wykonawcy miesięczne wynagrodzenie
            abonamentowe w kwocie ustalonej jako Wynagrodzenie Miesięczne w Formularzu Umowy. Jest
            to warunek konieczny do ostatecznego przekazania (przeniesienia na serwer, uruchomienia
            strony pod domeną) przez Wykonawcę przedmiotu Umowy.
          </li>
          <li>
            Zamawiający jest osobą decyzyjną we wszystkich sprawach dotyczących strony www i jego
            decyzje są wiążące dla realizacji projektu.
          </li>
          <li>
            Zamawiający udostępni Wykonawcy dostęp do niezbędnych danych dostępowych, klucze,
            licencje konieczne do wykonania Aktualizacji oprogramowania. Nie przekazanie informacji
            wyszczególnionych powyżej zwalnia Wykonawcę z obowiązku Aktualizacji oprogramowania do
            czasu przekazania informacji, z zachowaniem prawa do pełnego wynagrodzenia
            (wynagrodzenie za gotowość do świadczenia usług).
          </li>
          <li>
            Zamawiający zapewni Wykonawcy stały dostęp do Serwisu Internetowego, do kodu
            programistycznego Serwisu Internetowego (poprzez FTP lub w inny ustalony przez Strony
            sposób) oraz do bazy danych Serwisu Internetowego (poprzez phpmyadmin lub w inny
            ustalony przez Strony sposób). Brak dostępu do danych wyszczególnionych powyżej zwalnia
            Wykonawcę z obowiązku świadczenia Utrzymania, z zachowaniem prawa do pełnego
            wynagrodzenia (wynagrodzenie za gotowość do świadczenia usług).
          </li>
          <li>Wszystkie ustalenia muszą zostać potwierdzone drogą mailową.</li>
          <li>
            Każda rozmowa telefoniczna zostaje podsumowana mailowo. Zamawiający potwierdza w
            odpowiedzi na maila czy spisane mailowo ustalenia są zgodne z jego oczekiwaniami
            przedstawionymi w rozmowie telefonicznej.
          </li>
        </ul>
        <h3>§ 5. [Obowiązki Wykonawcy]</h3>
        <ul>
          <li>
            Wykonawca zobowiązuje się do regularnego administrowania, aktualizowania i
            zabezpieczenia serwisu internetowego Zamawiającego.
          </li>
          <li>
            Wykonawca będzie wykonywać prace w sposób zdalny (on-line) na Serwerze. Zamawiający jest
            zobowiązany zapewnić taką możliwość Wykonawcy.
          </li>
          <li>Wykonawca będzie wysyłać Zamawiającemu comiesięczny raport z wykonanych prac.</li>
        </ul>
        <h3>§ 6. [Odpowiedzialność Stron]</h3>
        <ul>
          <li>
            W razie samodzielnego ingerowania w stronę przez Zamawiającego np. poprzez instalację
            dodatkowych komponentów gwarancja zostaje utracona, a naprawy przez Wykonawcę
            realizowane są jako osobne zlecenia płatne. Nie dotyczy to sytuacji w której Zamawiający
            wyłącznie edytuje treści tekstowe i zdjęcia z panelu.
          </li>
          <li>
            Wykonawca nie gwarantuje działania strony internetowej jeśli nie będzie podlegać
            bieżącej aktualizacji.
          </li>
          <li>
            Wykonawca nie ponosi odpowiedzialności za zmiany w wyglądzie strony, wynikające
            wyłącznie z nowego designu wprowadzonego do aktualizacji poprzez autorów wtyczek lub
            systemów z których korzysta Zamawiający. Ponowne dopasowanie serwisu internetowego do
            starego wyglądu może wystąpić na życzenie Zamawiającego za dodatkową opłatą uzgodnioną
            wspólnie przez obie Strony.
          </li>
          <li>
            W wypadku, gdy Zamawiający nie umożliwi Wykonawcy przeprowadzenia prac aktualizacyjnych,
            na przykład, gdy Zamawiający korzysta z płatnego szablonu swojego serwisu internetowego,
            ale nie chce ponosić kosztów jego aktualizacji, Zamawiający płaci dodatkową stawkę
            uzgodnioną z Wykonawcą, lub przyjmuje do zrozumienia, że wykonywanie dalszych
            aktualizacji przez Wykonawcę staje się niemożliwe.
          </li>
          <li>
            Całkowita odpowiedzialność Wykonawcy względem Zamawiającego za szkody powstałe na skutek
            niewykonania lub nienależytego wykonania Umowy przez Wykonawcę jest ograniczona do strat
            Zamawiającego (z wyłączeniem utraconych korzyści) oraz kwotowo ograniczona do wysokości
            wynagrodzenia netto należnego Wykonawcy wynikającego z Umowy będącej podstawą
            odpowiedzialności za ostatnie 12 zakończonych miesięcy kalendarzowych. Strony wyłączają
            rękojmię za wady z tytułu wykonania Umowy.
          </li>
        </ul>
        <h3>§ 7. [Płatności]</h3>
        <ul>
          <li>
            Koszt miesięcznej realizacji Przedmiotu Umowy zawartego w §1 – Utrzymanie strony www
            jest ustalony w sekcji Wynagrodzenie Miesięczne w Formularzu Umowy.
          </li>
          <li>Okresem rozliczeniowym jest miesiąc kalendarzowy.</li>
          <li>
            Zamawiający zobowiązany jest do zapłaty Wykonawcy wynagrodzenia określonego w Formularzu
            Umowy. Wynagrodzenie będzie płatne z dołu w terminie określonym na Fakturze VAT, nie
            krótszym niż 7 dni. Wraz z Fakturą VAT Zamawiający otrzyma raport z czynności wykonanych
            w systemie. Podatek VAT zostanie doliczony w wysokości określonej przepisami.
          </li>
          <li>
            Utrzymanie może nie być świadczone w przypadku, gdy Zamawiający opóźnia się z płatnością
            na rzecz Wykonawcy o co najmniej 30 dni.
          </li>
        </ul>
        <h3>§ 8. [Poufność]</h3>
        <ul>
          <li>
            Wszystkie informacje dotyczące działalności Stron pozostaną ich wyłączną własnością.
            Żadna ze Stron nie może używać informacji dotyczącej drugiej Strony poza wypadkami
            określonymi w niniejszej Umowie
          </li>
          .
          <li>
            Informacje poufne oznaczają wszelkie informacje związane z działalnością Strony, jej
            kontrahentami lub ich działalnością, które nie są znane powszechnie, a które zostały
            uzyskane przez druga Stronę w związku z wykonywaniem warunków niniejszej Umowy.
          </li>
          <li>
            Strony zobowiązują się dotrzymać tajemnicy i poufności informacji uzyskanych w celu
            wykonania tej Umowy oraz nie ujawniać ich komukolwiek poza odpowiednimi pracownikami i
            tylko w celu prawidłowego wykonania tej Umowy.
          </li>
          <li>
            Wszelkie informacje uzyskane przez strony w trakcie wykonywania niniejszej Umowy mają
            charakter poufny i będą wykorzystywane wyłącznie dla celów związanych z jej realizacją,
            chyba że zachowanie poufności będzie wyłączone przez powszechnie obowiązujące przepisy
            prawa.
          </li>
          <li>
            Wykorzystanie informacji w innych celach niż wymienione w ust. 1 niniejszego paragrafu
            wymaga pisemnej zgody drugiej strony (tajemnica przedsiębiorstwa).
          </li>
          <li>
            Strony zobowiązują się nie rozpowszechniać, nie ujawniać, ani nie wykorzystywać
            informacji poufnych należących do drugiej Strony Umowy w szerszym zakresie niż jest to
            niezbędne do wykonania Umowy, niezależnie od formy i źródła ich uzyskania. Udostępnianie
            informacji powinno być kontrolowane i ograniczone do przypadków koniecznych,
            wynikających z realizacji Umowy.
          </li>
          <li>
            Strony zobowiązują się do utrzymywania w ścisłej tajemnicy informacji poufnych,
            uzyskanych od drugiej Strony w związku z realizacją Umowy i dotyczących drugiej Strony,
            jej klientów, pracowników, współpracowników i przedstawicieli oraz ich działalności
            zarówno w czasie trwania niniejszej Umowy jak i po jej zakończeniu Nie stanowią
            informacji poufnych – wszelkie informacje i dane podane do publicznej wiadomości lub
            upublicznione w inny sposób, jak też powszechnie znane lub uzyskane w dobrej w ierze od
            osób trzecich. Wykonawca jako Administrator danych oświadcza, że zgromadził przetwarzane
            dane osobowe przekazane przez Zamawiającego i przetwarza te dane osobowe zgodnie z
            bezwzględnie obowiązującymi przepisami prawa i Umową.
          </li>
          <li>
            Wykonawca nie ponosi odpowiedzialności ani nie jest zobligowany do naprawy strony
            Zamawiającego w ramach świadczonych usług, jeżeli na zlecenie Zamawiającego inny
            zewnętrzny zleceniobiorca spowoduje awarię strony poprzez swoją ingerencję.
          </li>
        </ul>
        <h3>§ 9. [Przetwarzanie danych osobowych]</h3>
        <ul>
          <li>
            Wszystkie przepisy w zakresie przetwarzania danych osobowych zawarte są w Umowie w
            zakresie przetwarzania danych osobowych zawartej między Stronami.
          </li>
        </ul>
        <h3>§ 10. [Inne ustalenia]</h3>
        <ul>
          <li>
            Umowa zawiera 10 (dziesięć) paragrafów. Ważna jest wyłącznie w formie pierwotnej i bez
            skreśleń. Wszelkie zmiany jej treści mogą zostać wprowadzone wyłącznie w formie
            pisemnego aneksu za zgodą obu stron pod rygorem nieważności.
          </li>
          <li>
            Umowa zawarta jest na czas określony w Formularzu Umowy („Podstawowy Okres Umowy”). Po
            upływie Podstawowego Okresu Umowy, Umowa obowiązuje nadal jako umowa na czas
            nieokreślony. Każda ze Stron może wypowiedzieć Umowę po upływie Podstawowego Okresu
            Umowy wysyłając oświadczenie na odpowiedni email kontaktowy drugiej Strony ze skutkiem
            na koniec następnego miesiąca po miesiącu w którym oświadczenia zostało złożone.
          </li>
          <li>
            W przypadkach nieokreślonych niniejszą Umowa mają zastosowanie odpowiednie przepisy
            Kodeksu Cywilnego.
          </li>
          <li>
            Ewentualne spory mogące wynikać na tle wykonania niniejszej Umowy, rozstrzygane będą
            przez właściwy sąd powszechny dla siedziby powoda.
          </li>
          <li>
            Każda ze Stron zobowiązuje się do nienawiązywania bezpośredniej i pośredniej współpracy
            opartej na jakiejkolwiek podstawie prawnej lub faktycznej z pracownikami i
            współpracownikami drugiej Strony – jeśli taka osoba byłby stroną spełniającą świadczenie
            charakterystyczne – w czasie obowiązywania Umowy i przez okres 18 miesięcy od jej
            zakończenia. Za każdy przypadek naruszenia Strona naruszająca zapłaci drugiej Stronie
            karę umowną w wysokości 50 000 zł (słownie: pięćdziesiąt tysięcy złotych)w terminie 7
            dni od wystąpienia naruszenia.
          </li>
          <li>
            Wykonawca ma prawo informować o fakcie współpracy z Zamawiającym oraz – ogólnie – o
            przedmiocie współpracy.
          </li>
          <li>
            Zamawiający i Wykonawca dołożą wszelkich starań zmierzających do polubownego
            rozstrzygnięcia, w drodze bezpośrednich negocjacji, jakichkolwiek nieporozumień i sporów
            między Stronami, które wynikną podczas realizacji Umowy. Jeżeli Strony nie rozstrzygną
            sporu w powyższy sposób, właściwym dla jego rozstrzygnięcia będzie Sąd Rejonowy dla
            miasta wykonawcy, albo sąd wyższego rzędu właściwy dla obszaru właściwości powyższego
            sądu.
          </li>
          <li>
            W ramach nie unormowanych niniejszą Umową odpowiednie zastosowanie znajdą odpowiednie
            przepisy prawa powszechnie obowiązującego, w szczególności kodeksu cywilnego oraz ustawy
            o prawie autorskim.
          </li>
          <li>
            Wszelkie zmiany Umowy i oświadczenia w niej przewidziane wymagają zachowania formy
            pisemnej pod rygorem nieważności, chyba że Strony dla poszczególnych kwestii wyraźnie
            dopuściły także inną formę.
          </li>
          <li>Umowa podlega polskiemu prawu.</li>
        </ul>
        {
          //
        }
        <h1>Umowa w zakresie przetwarzania danych osobowych</h1>
        <div>
          <p>zawarta w Krakowie:</p>
          <p>między Klientem wykupującym usługę abonamentową obsługi strony www</p>
          <p>
            zwanym dalej <strong>Administratorem</strong>
          </p>
          <p>a</p>
          <p>
            <strong>Agencja Interaktywna ADream sp. z o.o.</strong>
          </p>
          <p>ul. Chopina 18/3a</p>
          <p>30-047 Kraków</p>
          <p>NIP: 6762477695</p>
          <p>reprezentowaną przez: Łukasz Pach</p>
          <p>
            zwaną dalej <strong>Kontrahentem</strong>,
          </p>
          <p>
            zwane dalej łącznie <strong>Stronami</strong>, a każda z osobna <strong>Stroną</strong>,
            zwaną dalej <strong>Umową</strong>,
          </p>
          <p>o następującej treści:</p>
        </div>
        <h3>§ 1. [Oświadczenia Stron]</h3>
        <ul>
          <li>
            Strony oświadczają, że łączy je umowa w przedmiocie objęcia witryny www Administratora
            usługą wsparcia i nadzoru ForSecure (
            <strong>
              Umowa w zakresie administrowania i serwisowania strony www - Umowa główna
            </strong>
            ).
          </li>
          <li>W związku z wykonywaniem Umowy głównej przetwarzane są dane osobowe.</li>
          <li>
            Administratorem przetwarzanych danych osobowych jest Administrator. Kontrahent jest
            podmiotem przetwarzajacym dane osobowe (procesorem).
          </li>
          <li>
            Strony zawierają Umowę celem uregulowania kwestii powierzenia przetwarzania danych
            osobowych w związku z zawarciem i wykonywaniem Umowy głównej.
          </li>
        </ul>
        <h3>§ 2. [Przedmiot Umowy]</h3>
        <p>
          Klient jako administrator danych osobowych przetwarzanych przy wykonywaniu Umowy głównej
          powierza Kontrahentowi jako podmiotowi przetwarzającemu (procesorowi) czynności związane z
          przetwarzaniem tych danych osobowych na zasadach określonych w Umowie.
        </p>
        <h3>§ 3. [Charakter i cel przetwarzania]</h3>
        <p>
          Powierzone dane osobowe są przetwarzane w celu należytego wykonania Umowy głównej, to jest
          w celu realizacji usług stanowiących przedmiot Umowy głównej, a określonych w tej umowie
          oraz w ewentualnych zleceniach, regulaminach świadczenia usług lub korespondencji pomiędzy
          Stronami, pozostających w związku z zawarciem i wykonywaniem Umowy głównej. Strony
          potwierdzają, że powierzone dane osobowe nie mogą być przetwarzane w celu lub w zakresie
          innym niż określone w Umowie.
        </p>
        <ul>
          <li>
            Powierzone dane osobowe będą przetwarzane wyłącznie na zasadach określonych w Umowie
            oraz w obowiązujących przepisach prawa w zakresie ochrony danych osobowych, w tym w
            szczególności zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego i Rady (UE)
            2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z
            przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE, Dz.Urz. UE L 2016, Nr 119, s. 1, (<strong>RODO</strong>),
            które są obowiązujące od dnia 25 maja 2018 roku.
          </li>
          <li>
            Kontrahent oświadcza, że dysponuje zasobami, doświadczeniem, wiedzą fachową i
            wykwalifikowanym personelem, które umożliwią mu należyte wykonanie Umowy, w tym w
            szczególności wdrożenie odpowiednich środków technicznych i organizacyjnych mającyc222h
            na celu ochronę danych osobowych.
          </li>
        </ul>
        <h3>§ 4. [Zakres przetwarzania]</h3>
        <ul>
          <li>
            Rodzaje przetwarzanych przez Kontrahenta powierzonych danych osobowych są następujące:
          </li>
          <ul>
            <li>
              hasła dostępowe do panelu administracyjnego witryny, której dotyczy umowa główna
            </li>
            <li>
              dane logowania do usług powiązanych, a niezbędnych do funkcjonowania witryny tj. dane
              dostępowe do usługi hostingowej, bazy danych, serwera ftp
            </li>
            <li>dane użytkowników witryny dostępne z poziomu panelu administracyjnego</li>
          </ul>
          <li>
            Kategorie osób, których dotyczą przetwarzane przez Kontrahenta powierzone dane osobowe
            są następujące:
          </li>
          <ul>
            <li>administratorzy i redaktorzy witryny, których dotyczy Umowa główna</li>
          </ul>
          <li>
            Szczegółowe cele przetwarzanych przez Kontrahenta powierzonych danych osobowych są
            następujące:
          </li>
          <ul>
            <li>
              operowanie danymi dostępowymi na potrzeby sprawowania usług administracji konserwacji
              i nadzoru
            </li>
          </ul>
          <li>
            Przedmiotem przetwarzania przez Kontrahenta są następujące czynności przetwarzania:
          </li>
          <ul>
            <li>gromadzenie, archiwizowanie w sposób zgodny ze sztuką</li>
          </ul>
        </ul>
        <h3>§ 5. [Czas przetwarzania] </h3>
        <p>
          Z zastrzeżeniem ust. 2 poniżej, powierzone dane osobowe będą przetwarzane przez
          Kontrahenta wyłącznie przez czas niezbędny do osiągnięcia celów przetwarzania tych danych,
          to jest przez czas obowiązywania Umowy Głównej. Powierzone dane osobowe nigdy nie będą
          przetwarzane dłużej niż przez czas trwania Umowy, z zastrzeżeniem bezwzględnie
          obowiązujących przepisów prawa.
        </p>
        <h3>§ 6. [Obowiązki Administratora]</h3>
        <p>
          Administrator oświadcza, że zgromadził przetwarzane dane osobowe powierzone Kontrahentowi
          i przetwarza te dane osobowe zgodnie z bezwzględnie obowiązującymi przepisami prawa i
          Umową główną.
        </p>
        <h3>§ 7. [Obowiązki Kontrahenta]</h3>
        <ul>
          <li>
            Kontrahent przetwarzać będzie powierzone dane osobowe wyłącznie zgodnie z Umową – co
            dotyczy też przekazywania danych osobowych do państwa trzeciego lub organizacji
            międzynarodowej – chyba, że taki obowiązek nakłada na Kontrahenta prawo Unii
            Europejskiej lub prawo państwa członkowskiego, któremu podlega Kontrahent. W takim
            przypadku, przed rozpoczęciem przetwarzania Kontrahent poinformuje Administratora o tym
            obowiązku prawnym, o ile prawo to nie zabrania udzielania takiej informacji z uwagi na
            ważny interes publiczny.
          </li>
          <li>
            Strony potwierdzają, że Kontrahent nie będzie przekazywać powierzonych mu do
            przetwarzania danych osobowych do podmiotów w państwach trzecich, to jest spoza
            Europejskiego Obszaru Gospodarczego.
          </li>
          <li>
            Strony potwierdzają, że Kontrahent będzie przetwarzać powierzone mu dane osobowe
            wyłącznie zgodnie z udokumentowanymi poleceniami Administratora, przy czym za takie
            udokumentowane polecenie uważa się polecenie przekazywane drogą elektroniczną lub na
            piśmie albo wynikające w sposób jasny z Umowy.
          </li>
          <li>
            Kontrahent zapewnia, by osoby upoważnione do przetwarzania danych osobowych zobowiązały
            się pisemnie do zachowania tajemnicy lub by podlegały odpowiedniemu ustawowemu
            obowiązkowi zachowania tajemnicy, co najmniej na poziomie jak obowiązek zachowania
            poufności wynikający z Umowy głównej. Administrator ma prawo do weryfikacji spełnienia
            powyższego obowiązku przez Kontrahenta, a Kontrahent na wezwanie Administratora
            niezwłocznie przedstawi informacje lub dokumenty związane z wykonaniem powyższego
            obowiązku.
          </li>
          <li>
            Kontrahent oświadcza, że podjął skuteczne środki techniczne i organizacyjne mające na
            celu należyte wykonanie Umowy i ochronę powierzonych do przetwarzania danych osobowych.
            Niezależnie od powyższego, Administrator może żądać od Kontrahenta przeprowadzenia
            analizy ryzyka właściwej dla przetwarzania powierzonych mu danych osobowych oraz
            wdrożenia środków minimalizujących to ryzyko. Administrator może żądać przedstawienia
            powyższej analizy ryzyka i jej wniosków, a także zastosowania sugerowanych przez
            Administratora środków ochrony, do czego Kontrahent się zobowiązuje.
          </li>
          <li>
            Kontrahent podejmuje wszelkie środki wymagane na mocy art. 32 RODO, według zasad jak
            niżej.
          </li>
          <ul>
            <li>
              Uwzględniając stan wiedzy technicznej, koszt wdrażania oraz charakter, zakres,
              kontekst i cele przetwarzania oraz ryzyko naruszenia praw lub wolności osób fizycznych
              o różnym prawdopodobieństwie wystąpienia i wadze zagrożenia, Kontrahent wdraża
              odpowiednie środki techniczne i organizacyjne, aby zapewnić stopień bezpieczeństwa
              odpowiadający temu ryzyku, w tym między innymi w stosownym przypadku: (i)
              pseudonimizację i szyfrowanie danych osobowych, (ii) zdolność do ciągłego zapewnienia
              poufności, integralności, dostępności i odporności systemów i usług przetwarzania,
              (iii) zdolność do szybkiego przywrócenia dostępności danych osobowych i dostępu do
              nich w razie incydentu fizycznego lub technicznego, oraz (iv) regularne testowanie,
              mierzenie i ocenianie skuteczności środków technicznych i organizacyjnych mających
              zapewnić bezpieczeństwo przetwarzania.
            </li>
            <li>
              Kontrahent oceniając, czy stopień bezpieczeństwa jest odpowiedni, uwzględnia przede
              wszystkim ryzyko wiążące się z przetwarzaniem, a w szczególności wynikające z
              przypadkowego lub niezgodnego z prawem zniszczenia, utraty, modyfikacji,
              nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych osobowych
              przesyłanych, przechowywanych lub w inny sposób przetwarzanych.
            </li>
            <li>
              Kontrahent podejmuje działania w celu zapewnienia, by każda osoba fizyczna działająca
              z jego upoważnienia, która ma dostęp do danych osobowych, przetwarzała je wyłącznie na
              jego polecenie, chyba że wymaga tego od niej prawo Unii Europejskiej lub prawo państwa
              członkowskiego.
            </li>
          </ul>
          <li>
            Biorąc pod uwagę charakter przetwarzania, Kontrahent w miarę możliwości pomaga
            Administratorowi poprzez odpowiednie środki techniczne i organizacyjne wywiązać się z
            obowiązku odpowiadania na żądania osoby, której dane dotyczą, w zakresie wykonywania jej
            praw. W tym celu Kontrahent przekaże Administratorowi wszelkie informacje i dokumenty
            niezwłocznie, jednak nie później niż w terminie 3 (trzech) dni od dnia zgłoszenia
            zapotrzebowania przez Administratora.
          </li>
          <li>
            Uwzględniając charakter przetwarzania oraz udostępnione mu informacje, Kontrahent pomaga
            Administratorowi wywiązać się z obowiązków określonych w art. 32–36 RODO.
          </li>
          <li>
            Kontrahent udostępnia Administratorowi wszelkie informacje niezbędne do wykazania
            spełnienia obowiązków określonych w przepisach i związanych z powierzeniem danych
            osobowych do przetwarzania oraz umożliwia Administratorowi lub audytorowi upoważnionemu
            przez Administratora przeprowadzanie audytów, w tym inspekcji, i przyczynia się do nich.
            Administrator ma prawo do przeprowadzania audytów i inspekcji, o których mowa powyżej,
            poprzez żądanie udzielenia informacji lub okazania dokumentów, a także poprzez
            bezpośrednią kontrolę w miejscu/miejscach przetwarzania przez Kontrahenta powierzonych
            danych osobowych. Uprawnienia Administratora wskazane powyżej będą wykonywane przez
            Kontrahenta niezwłocznie, w terminie wskazanym przez Administratora. Kontrahent podejmie
            wszelkie czynności organizacyjne i podobne, by umożliwić Administratorowi realizację
            uprawnień wskazanych powyżej w najszerszym możliwym zakresie.
          </li>
          <li>
            Kontrahent niezwłocznie poinformuje Administratora, jeżeli jego zdaniem wydane mu
            polecenie stanowi naruszenie RODO lub innych przepisów Unii Europejskiej lub państwa
            członkowskiego o ochronie danych.
          </li>
          <li>
            Kontrahent zobowiązuje się prowadzić rejestr wszystkich kategorii czynności
            przetwarzania danych osobowych, o którym mowa w art. 30 ust. 2 RODO, dla danych
            osobowych powierzonych w ramach Umowy. Rejestr zostanie udostępniony Administratorowi na
            każde jej żądanie niezwłocznie, jednak nie później niż w terminie 7 (siedmiu) dni od
            dnia zgłoszenia tego żądania.
          </li>
          <li>
            Kontrahent po stwierdzeniu naruszenia ochrony danych osobowych bez zbędnej zwłoki,
            jednak nie później niż w terminie 24 (dwudziestu czterech) godzin od chwili jego
            zaistnienia, zgłasza je Administratorowi, w tym informacje niezbędne Administratorowi do
            zgłoszenia naruszenia ochrony danych organowi nadzorczemu. Jeżeli – i w zakresie, w
            jakim – informacji nie da się udzielić w tym samym czasie, Kontrahent może jej udzielać
            sukcesywnie bez zbędnej zwłoki, z uwzględnieniem powyższego terminu. Na każde żądanie
            Administratora Kontrahent jest zobowiązany udzielić niezwłocznie Administratorowi
            wszelkich dalszych informacji lub okazać dokumenty związane z naruszeniem lub przeszłymi
            naruszeniami, z zachowaniem terminu wskazanego powyżej. Zgłoszenie, o którym mowa
            powyżej będzie odbywać się z wykorzystaniem danych kontaktowych (adres e-mail i telefon)
            wykorzystywanych do realizacji umowy głównej.
          </li>
          <li>Niezależnie od powyższego, Kontrahent zobowiązuje się do:</li>
          <ul>
            <li>
              niezwłocznego, nie później niż w terminie 3 (trzech) dni od dnia powzięcia wiedzy,
              poinformowania Administratora o wszczęciu jakiegokolwiek postępowania kontrolnego,
              administracyjnego, sądowego, przygotowawczego lub innego podobnego dotyczącego
              powierzonych Kontrahentowi do przetwarzania danych osobowych, a także o zgłoszeniu
              jakiegokolwiek roszczenia związanego z przetwarzaniem powierzonych danych osobowych;
              Kontrahent będzie również na każde żądanie Administratora informował go o aktualnym
              statusie powyższych postępowań lub roszczeń;
            </li>
            <li>
              stosowania się do ewentualnych wskazówek lub zaleceń, wydanych przez organ nadzoru lub
              unijny organ doradczy zajmujący się ochroną danych osobowych, dotyczących powierzonych
              do przetwarzania danych osobowych;
            </li>
            <li>
              stosowania się do ewentualnych uzasadnionych wskazówek lub zaleceń Administratora co
              do rodzaju zastosowanych środków technicznych lub organizacyjnych dla ochrony danych
              osobowych na podstawie Umowy, w tym w szczególności będących wynikiem realizacji przez
              Administratora uprawnień do przeprowadzania audytów i inspekcji;
            </li>
            <li>
              współdziałania z Administratorem przy zapewnieniu przestrzegania obowiązków
              wynikających z dokonania oceny skutków dla ochrony danych, o której mowa w art. 35
              RODO;
            </li>
            <li>
              skonsultowania się z organem nadzorczym w sytuacji, gdy ocena skutków dla ochrony
              danych, o której mowa w art. 35 RODO, wykaże wysokie ryzyko przetwarzania;
            </li>
            <li>
              stosowania zatwierdzonych kodeksów postępowania oraz zatwierdzonych mechanizmów
              certyfikacji, jeśli Administrator podejmie decyzję o ich zastosowaniu w stosunku do
              danych osobowych, których jest administratorem;
            </li>
            <li>
              zapewnienia, by wyznaczony przez Kontrahenta inspektor ochrony danych był w stanie
              wypełniać swoje obowiązki i zadania w sposób niezależny oraz posiadał wszelkie
              wymagane przez RODO kwalifikacje zawodowe i wiedzę fachową;
            </li>
            <li>
              bieżącego monitorowania swojego systemu ochrony danych osobowych oraz szkolenia
              pracowników i innych osób upoważnionych do dostępu do tego systemu i przetwarzanych
              danych osobowych, z prawem Administratora do informacji odnośnie czynności
              podejmowanych w powyższym zakresie i wydawania wskazówek lub zaleceń w powyższym
              zakresie, do których Kontrahent będzie się stosował;
            </li>
            <li>
              współdziałania z Administratorem w każdym innym aspekcie związanym z przetwarzaniem
              powierzonych danych osobowych.
            </li>
          </ul>
        </ul>
        <h3>§ 8. [Dalsze powierzenie danych osobowych do przetwarzania]</h3>
        <p>
          Administrator wyraża zgodę na dalsze powierzenie przetwarzania danych osobowych przez
          Kontrahenta jego procesorom.
        </p>
        <h3>§ 9. [Odpowiedzialność Stron]</h3>
        <ul>
          <li>
            Każda ze Stron ponosi odpowiedzialność w zakresie swoich praw i obowiązków określonych w
            Umowie oraz w bezwzględnie obowiązujących przepisach prawa.
          </li>
          <li>
            {' '}
            Niezależnie od powyższego oraz niezależnie od innych praw i obowiązków zapisanych w
            Umowie, Kontrahent ponosi wszelką odpowiedzialność wobec osób trzecich w związku ze
            swoimi działaniami lub zaniechaniami związanymi z zawarciem lub wykonywaniem Umowy, w
            tym w szczególności wobec osób, których dane osobowe są przetwarzane. W przypadku, gdyby
            jakakolwiek osoba trzecia w powyższej sytuacji wystąpiła z roszczeniami bezpośrednio
            wobec Administratora, Kontrahent zobowiązuje się zwolnić Administratora z
            odpowiedzialności i wstąpić do odpowiednich postępowań. W przypadku niewykonania,
            nienależytego wykonania lub prawnej niemożliwości albo utrudnienia w wykonaniu
            powyższego obowiązku przez Kontrahenta w sposób niezwłoczny, Administrator ma prawo do
            samodzielnego działania w sprawie, a Kontrahent pokryje wszelkie koszty z tym związane,
            w tym w szczególności koszty profesjonalnego doradztwa i zastępstwa prawnego.
          </li>
        </ul>
        <h3>§ 10. [Usunięcie danych]</h3>
        <p>
          Po wygaśnięciu Umowy głównej, zależnie od decyzji Administratora, Kontrahent usuwa lub
          zwraca Administratorowi wszelkie dane osobowe oraz usuwa wszelkie ich istniejące kopie,
          chyba że prawo Unii Europejskiej lub prawo państwa członkowskiego nakazują przechowywanie
          danych osobowych. Kontrahent wykonuje powyższy obowiązek niezwłocznie po wygaśnięciu Umowy
          głównej, lecz nie później niż w terminie 7 (siedmiu) dni od dnia jej wygaśnięcia.
          Kontrahent wykonuje powyższy obowiązek (w przypadku zwracania danych osobowych) w sposób i
          w formacie umożliwiającym ich dalsze przetwarzanie i wykorzystywanie do tych samych celów,
          dla których przetwarzał je Kontrahent. Kontrahent wykonuje powyższy obowiązek
          protokolarnie przekazując Administratorowi odpowiedni protokół usunięcia lub zwrotu danych
          na każde wezwanie Administratora, nie później jednak niż w terminie 7 (siedmiu) dni od
          dnia sporządzenia tego wezwania. Protokół, o którym mowa powyżej, zawiera co najmniej
          następujące dane: rodzaj usuwanych/zwracanych danych oraz sposób usunięcia/zwrotu danych.
          Na każde żądanie Administratora, nie później niż w terminie 7 (siedmiu) dni od dnia
          sporządzenia tego wezwania, Kontrahent poinformuje Administratora pisemnie czy przetwarza
          dane osobowe powierzone mu przez Administratora, na mocy ww. obowiązków wynikających z
          prawa Unii Europejskiej lub prawa państwa członkowskiego nakazujących przechowywanie
          danych osobowych, a w przypadku informacji pozytywnej Kontrahent wskaże również co
          najmniej rodzaj przetwarzanych danych, zakres przetwarzania danych, cel przetwarzania
          danych, podstawę prawną przetwarzania danych oraz planowany dalszy czas przetwarzania
          danych, a także udzieli Administratorowi wszelkich dalszych informacji w tym zakresie.
        </p>
        <h3>§ 11. [Postanowienia końcowe]</h3>
        <ul>
          <li>W pozostałym zakresie Umowa główna pozostaje bez zmian.</li>
          <li>
            W przypadku, gdyby Umowa główna zawierała jakiekolwiek postanowienia w przedmiocie
            powierzenia przetwarzania danych osobowych, postanowienia te od chwili podpisania Umowy
            uznaje się za uchylone, a w ich miejsce wchodzą postanowienia Umowy.
          </li>
          <li>
            Powierzenie przetwarzania danych osobowych, którego dotyczy Umowa, następuje w ramach
            wynagrodzenia za wykonanie Umowy głównej.
          </li>
          <li>
            W sprawach nieuregulowanych w Umowie zastosowanie mają odpowiednie przepisy prawa
            polskiego.
          </li>
          <li>
            Wszelkie spory mogące wyniknąć na tle Umowy Strony poddają pod rozstrzygnięcie
            właściwego rzeczowo sądu powszechnego dla siedziby Kontahenta.
          </li>
          <li>Każda zmiana Umowy będzie dokonana na piśmie pod rygorem nieważności.</li>
        </ul>
      </section>
    </BasicLayout>
  );
}
