import { defineMessages } from 'react-intl';

export default defineMessages({
  SESSION__RESET_PASS__PAGE_HEADER_TITTLE: {
    id: 'session.reset_pass.page_header_title',
    defaultMessage: 'Resetowania hasła'
  },
  SESSION__RESET_PASS__PAGE_FORM_TITTLE: {
    id: 'session.reset_pass.page_form_title',
    defaultMessage: 'Zresetuj hasło'
  },
  SESSION__RESET_PASS__SUBMIT_BUTTON: {
    id: 'session.reset_pass.submit_button',
    defaultMessage: 'Resetuj hasło'
  },
  SESSION__RESET_PASS__EMAIL_FIELD_LABEL: {
    id: 'session.reset_pass.email_field_label',
    defaultMessage: 'Adres e-mail'
  },
  SESSION__RESET_PASS__VALIDATION_EMAIL_IS_EMPTY: {
    id: 'session.reset_pass.validation_email_is_empty',
    defaultMessage: 'Podaj adres email'
  },
  SESSION__RESET_PASS__SUBMIT_SUCCESS: {
    id: 'session.reset_pass.submit_success',
    defaultMessage: 'Na podany email wysłano link do zresetowania hasła'
  },
  SESSION__RESET_PASS__SUBMIT_FAILURE: {
    id: 'session.reset_pass.submit_failure',
    defaultMessage: 'Wprowadzone dane są nieprawidłowe. Spróbuj ponownie'
  }
});
