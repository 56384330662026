import React from 'react';

import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';

const useSubmitAction = ({
  action,
  errorrFormattedMessage,
  successFormattedMessage,
  afterSuccess
} = {}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    args =>
      dispatch(action(args))
        .then(data => {
          successFormattedMessage &&
            toastr.success('', {
              component: successFormattedMessage
            });
          afterSuccess?.(data);
        })
        .catch(error => {
          console.error(error);

          if (error?.data) {
            toastr.warning('', {
              component: (
                <FormattedMessage
                  id="forms.correct_the_form_toastr"
                  defaultMessage="Popraw formularz"
                />
              )
            });

            throw new SubmissionError(error?.data);
          } else {
            !error?.data &&
              errorrFormattedMessage &&
              toastr.error('', {
                component: errorrFormattedMessage
              });
          }
        }),
    [dispatch, action, errorrFormattedMessage, successFormattedMessage, afterSuccess]
  );

  return [onSubmit];
};

export default useSubmitAction;
