import { defineMessages } from 'react-intl';

export default defineMessages({
  SESSION__EMAIL_VERIFICATION__EMAIL_VERIFIED_SUCCESS: {
    id: 'session.email_verification.email_verified_success',
    defaultMessage: 'Adres email został zweryfikowany'
  },
  SESSION__EMAIL_VERIFICATION__EMAIL_VERIFIED_FAILURE: {
    id: 'session.email_verification.email_verified_failure',
    defaultMessage: 'Wystąpił błąd podczas weryfikacji adresu email.'
  },
  SESSION__EMAIL_VERIFICATION__VERIFICATION_LOADING: {
    id: 'session.email_verification.verification_loading',
    defaultMessage: 'Trwa weryfikacja adresu email.'
  }
});
