import produce from 'immer';

import actionType from 'utils/actionType';
import taskState from 'modules/_task/consts/taskState';
import { USER_HOME_FETCH_PAGE_TASKS } from 'modules/User/UserHome/actionType';

const INITIAL_STATE = {
  tasks: null,
  tasksByState: {
    [taskState.TODO]: {},
    [taskState.IN_PROGRESS]: {},
    [taskState.DONE]: {}
  },
  statuses: {
    fetchingUserPageTasksByState: {
      [taskState.TODO]: false,
      [taskState.IN_PROGRESS]: false,
      [taskState.DONE]: false
    }
  },
  errors: {
    afterFetchingUserPageTasksByState: {
      [taskState.TODO]: false,
      [taskState.IN_PROGRESS]: false,
      [taskState.DONE]: false
    }
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(USER_HOME_FETCH_PAGE_TASKS): {
        const { taskState } = action.data;

        state.statuses.fetchingUserPageTasksByState[taskState] = true;
        state.errors.afterFetchingUserPageTasksByState[taskState] = false;

        state.tasksByState[taskState] = INITIAL_STATE.tasksByState[taskState];

        return state;
      }

      case actionType.SUCCESS(USER_HOME_FETCH_PAGE_TASKS): {
        const actions = action?.payload?.data?.actions;
        const { taskState } = action.data;

        state.statuses.fetchingUserPageTasksByState[taskState] = false;
        state.errors.afterFetchingUserPageTasksByState[taskState] = false;

        state.tasksByState[taskState] = {
          tasks: actions.data || [],
          stats: {
            from: actions.from,
            per_page: actions.per_page,
            to: actions.to,
            total: actions.total,
            current: actions.current_page
          }
        };

        return state;
      }
      case actionType.ERROR(USER_HOME_FETCH_PAGE_TASKS): {
        const { taskState } = action.data;

        state.statuses.fetchingUserPageTasksByState[taskState] = false;
        state.errors.afterFetchingUserPageTasksByState[taskState] = true;

        return state;
      }

      default:
        return state;
    }
  });
