import { mainPaths } from 'const/paths';
import userRole from 'const/userRole';

import Login from 'modules/Session/Login/Login';
import PrivacyPolicy from 'containers/public/PrivacyPolicy';
import Register from 'modules/Session/Register/Register';
import RegisterConfirm from 'modules/Session/RegisterConfirm/RegisterConfirm';
import ResetPass from 'modules/Session/ResetPass/ResetPass';
import Admin from 'modules/AdminPanel';
import Logout from 'modules/Session/Logout';
import User from 'modules/User';
import ResetPassToken from 'modules/Session/ResetPass/ResetPassToken';
import EmailVerification from 'modules/Session/EmailVerification/EmailVerification';

export const commonRoutes = [
  {
    path: mainPaths.RESET_PASSWORD_TOKEN,
    component: ResetPassToken
  },
  {
    path: mainPaths.PRIVACY_POLICY,
    component: PrivacyPolicy
  },
  {
    path: mainPaths.EMAIL_VERIFICATION,
    component: EmailVerification
  }
];

export const onlyPublicRoutes = [
  {
    path: mainPaths.LOGIN,
    component: Login
  },
  {
    path: mainPaths.REGISTER,
    component: Register
  },
  {
    path: mainPaths.REGISTER_CONFIRM,
    component: RegisterConfirm
  },
  {
    path: mainPaths.RESET_PASSWORD,
    component: ResetPass
  }
];

export const privateRoutes = [
  {
    path: mainPaths.LOGOUT,
    component: Logout
  }
];

export const privateRouteByUserRole = {
  [userRole.USER]: {
    path: mainPaths.USER_PANEL,
    component: User
  },
  [userRole.ADMIN]: {
    path: mainPaths.ADMIN_PANEL,
    component: Admin
  },
  [userRole.SUPER_ADMIN]: {
    path: mainPaths.ADMIN_PANEL,
    component: Admin
  }
};
