import { defineMessages } from 'react-intl';

export default defineMessages({
  SESSION__REGISTER_CONFIRM__PAGE_HEADER_TITLE: {
    id: 'session.register_confirm.page_header_title',
    defaultMessage: 'Dziękujemy za rejestrację'
  },
  SESSION__REGISTER_CONFIRM__PAGE_TITLE: {
    id: 'session.register_confirm.page_title',
    defaultMessage: 'Dziękujemy za rejestrację'
  },
  SESSION__REGISTER_CONFIRM__MESSAGE: {
    id: 'session.register_confirm.message',
    defaultMessage: `Serdecznie dziękujemy za rejestrację w naszym serwisie i chęć skorzystania z naszej
          usługi.
          \n\n Na podany przez Ciebie w procesie rejestracji adres e-mail wysłaliśmy link do
          aktywacji Twojego konta.`
  },
  SESSION__REGISTER_CONFIRM__LOGIN: {
    id: 'session.register_confirm.login',
    defaultMessage: `Zaloguj się`
  },
  SESSION__REGISTER_CONFIRM__MAIN_PAGE: {
    id: 'session.register_confirm.main-page',
    defaultMessage: `Strona główna`
  }
});
