import "./register.scss";

import React, { useCallback } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { useHistory, useLocation } from "react-router";
import { Field, Form, reduxForm } from "redux-form";
import { trim } from "lodash";
import { useFieldValidate } from "libs/reduxForm/hooks/useFieldValidate";

import { mainPaths } from "const/paths";

import { BasicLayout } from "components";
import Button from "components/Button/Button";
import InputComponent from "components/FormComponents/InputComponent";
import LinkButton from "components/LinkButton/LinkButton";

import { registerUser } from "modules/Session/actions";

import fieldValidation from "libs/reduxForm/fieldValidation";
import useSubmitAction from "libs/reduxForm/hooks/useSubmitAction";
import { defineMessage } from "react-intl";

const mainClassName = "register";
const mainMessageId = "session.register";

const registerEmailRequired = fieldValidation.createRequired(
  defineMessage({
    id: `${mainMessageId}.validation_email_is_empty`,
    defaultMessage: "Podaj adres e-mail",
  })
);
const registerPasswordRequired = fieldValidation.createRequired(
  defineMessage({
    id: `${mainMessageId}.validation_password_is_empty`,
    defaultMessage: "Podaj hasło",
  })
);

const Register = ({ handleSubmit, submitting }) => {
  const history = useHistory();
  const location = useLocation();

  const emailValidation = useFieldValidate([
    registerEmailRequired,
    fieldValidation.email,
  ]);
  const passwordValidate = useFieldValidate([registerPasswordRequired]);

  const afterRegisterSuccess = useCallback(() => {
    history.push({
      ...location,
      pathname: mainPaths.REGISTER_CONFIRM,
    });
  }, [location, history]);

  const [onSubmit] = useSubmitAction({
    action: registerUser,
    afterSuccess: afterRegisterSuccess,
    errorrFormattedMessage: (
      <FormattedMessage
        id={`${mainMessageId}.register_failure`}
        defaultMessage="Wystąpił błąd podczas rejestracji. Spróbuj ponownie"
      />
    ),
  });

  return (
    <>
      <FormattedMessage
        id={`${mainMessageId}.page_title`}
        defaultMessage="Panel rejestracji"
      >
        {(chunks) => (
          <Helmet>
            <title>{chunks}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <BasicLayout
        headerActions={
          <>
            <LinkButton
              to={{
                ...location,
                pathname: "/login",
              }}
              withBrackets
              kind="outline"
              disabled={submitting}
            >
              <FormattedMessage
                id={`${mainMessageId}.log_in_button`}
                defaultMessage="Zaloguj się"
              />
            </LinkButton>
          </>
        }
      >
        <div className={mainClassName}>
          <h2 className={`${mainClassName}__form-title`}>
            <FormattedMessage
              id={`${mainMessageId}.form_title`}
              defaultMessage="Rejestracja"
            />
          </h2>
          <p className={`${mainClassName}__form-description`}>
            <FormattedMessage
              id={`${mainMessageId}.form_description`}
              defaultMessage="Utwórz swoje konto i zapewnij sobie i swoim klientom szybką i bezpieczną stronę internetową."
            />
          </p>
          <Form
            className={`${mainClassName}__form`}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Field
                name="email"
                component={InputComponent}
                label={
                  <FormattedMessage
                    id={`${mainMessageId}.email_input_label`}
                    defaultMessage="E-mail"
                  />
                }
                validate={emailValidation}
                autoComplete="email"
                normalize={(value) => trim(value)}
                autoFocus
              />
              <Field
                name="password"
                component={InputComponent}
                description={
                  <FormattedMessage
                    id={`${mainMessageId}.password_hint_description`}
                    defaultMessage="Hasło musi się skłądać minimum z 8 znaków, w tym 1 duża litera, 1 mała litera i jedna cyfra."
                  />
                }
                type="password"
                label={
                  <FormattedMessage
                    id={`${mainMessageId}.password_input_label`}
                    defaultMessage="Hasło"
                  />
                }
                validate={passwordValidate}
                autoComplete="new-password"
              />
            </div>
            <div className={`${mainClassName}__form-info`}>
              <FormattedMessage
                id={`${mainMessageId}.privacy_policy_info`}
                defaultMessage="Zakładając konto w naszym serwisie akceptujesz naszą <privacyPolicyButton>Politykę Prywatności</privacyPolicyButton>"
                values={{
                  privacyPolicyButton: (chunks) => (
                    <LinkButton
                      href="https://forsecure.pl/polityka-prywatnosci/"
                      rel="noopener noreferrer"
                      target="_blank"
                      type="link"
                    >
                      {chunks}
                    </LinkButton>
                  ),
                }}
              />
            </div>
            <div className={`${mainClassName}__submit-wrapper`}>
              <Button
                withBrackets
                kind="outline"
                type="submit"
                loading={submitting}
              >
                <FormattedMessage
                  id={`${mainMessageId}.save_button_label`}
                  defaultMessage="Utwórz konto"
                />
              </Button>
            </div>
          </Form>
        </div>
      </BasicLayout>
    </>
  );
};

export default compose(
  reduxForm({
    form: "RegisterUser",
  })
)(Register);
