import produce from 'immer';
import actionType from 'utils/actionType';
import { filter } from 'lodash';

import {
  ADD_ADMIN_TASK_TEMPLATE,
  DELETE_ADMIN_TASK_TEMPLATE,
  FETCH_ADMIN_TASK_TEMPLATES,
  UPDATE_ADMIN_TASK_TEMPLATE
} from 'modules/AdminPanel/AdminTaskTemplates/actionType';

const INITIAL_STATE = {
  taskTemplatesList: null,
  statuses: {
    fetchingTaskTemplatesList: false,
    addingTaskTemplate: false,
    deletingTaskTemplate: {},
    updatingTaskTemplate: {}
  },
  errors: {
    afterFetchingTaskTemplatesList: false,
    afterAddingTaskTemplate: false,
    afterDeletingTaskTemplate: {},
    afterUpdatingTaskTemplate: {}
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(FETCH_ADMIN_TASK_TEMPLATES): {
        state.taskTemplatesList = INITIAL_STATE.taskTemplatesList;
        state.errors.afterFetchingTaskTemplatesList = false;
        state.statuses.fetchingTaskTemplatesList = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_ADMIN_TASK_TEMPLATES): {
        state.taskTemplatesList = action.payload.data;
        state.errors.afterFetchingTaskTemplatesList = false;
        state.statuses.fetchingTaskTemplatesList = false;

        return state;
      }

      case actionType.ERROR(FETCH_ADMIN_TASK_TEMPLATES): {
        state.errors.afterFetchingTaskTemplatesList = true;
        state.statuses.fetchingTaskTemplatesList = false;

        return state;
      }

      case actionType.REQUEST(DELETE_ADMIN_TASK_TEMPLATE): {
        const { actionId } = action;

        state.statuses.deletingTaskTemplate[actionId] = true;
        state.errors.afterDeletingTaskTemplate[actionId] = false;

        return state;
      }

      case actionType.SUCCESS(DELETE_ADMIN_TASK_TEMPLATE): {
        const { actionId } = action;

        state.taskTemplatesList = filter(state.taskTemplatesList, action => action.id !== actionId);

        state.statuses.deletingTaskTemplate[actionId] = false;
        state.errors.afterDeletingTaskTemplate[actionId] = false;

        return state;
      }

      case actionType.ERROR(DELETE_ADMIN_TASK_TEMPLATE): {
        const { actionId } = action;

        state.statuses.deletingTaskTemplate[actionId] = false;
        state.errors.afterDeletingTaskTemplate[actionId] = true;

        return state;
      }

      case actionType.REQUEST(ADD_ADMIN_TASK_TEMPLATE): {
        state.statuses.addingTaskTemplate = true;
        state.errors.afterAddingTaskTemplate = false;

        return state;
      }

      case actionType.SUCCESS(ADD_ADMIN_TASK_TEMPLATE): {
        const { data } = action.payload;

        state.taskTemplatesList = [...(state.taskTemplatesList || []), data];

        state.statuses.addingTaskTemplate = false;
        state.errors.afterAddingTaskTemplate = false;

        return state;
      }

      case actionType.ERROR(ADD_ADMIN_TASK_TEMPLATE): {
        state.statuses.addingTaskTemplate = false;
        state.errors.afterAddingTaskTemplate = true;

        return state;
      }

      case actionType.REQUEST(UPDATE_ADMIN_TASK_TEMPLATE): {
        const { actionId } = action;

        state.statuses.updatingTaskTemplate[actionId] = true;
        state.errors.afterUpdatingTaskTemplate[actionId] = false;

        return state;
      }

      case actionType.SUCCESS(UPDATE_ADMIN_TASK_TEMPLATE): {
        const { actionId, payload } = action;

        state.taskTemplatesList = state.taskTemplatesList.map(action => {
          if (action.id === actionId) {
            return payload.data;
          }

          return action;
        });

        state.statuses.updatingTaskTemplate[actionId] = false;
        state.errors.afterUpdatingTaskTemplate[actionId] = false;

        return state;
      }

      case actionType.ERROR(UPDATE_ADMIN_TASK_TEMPLATE): {
        const { actionId } = action;

        state.statuses.updatingTaskTemplate[actionId] = false;
        state.errors.afterUpdatingTaskTemplate[actionId] = true;

        return state;
      }

      default:
        return state;
    }
  });
