// import {
import { getUserToken } from 'modules/Session/getters';
//   refreshUserAuth,
//   setRedirectFlag,
//   registerUnauthorizedAction,
//   deleteAccessToken,
//   deleteRefreshToken
// } from '@modules/Session/actions';
import actionType, { PREFIXES } from 'utils/actionType';

import { FetchError } from './FetchError';

const defaultTransform = data => data;

const createStoreActionCreators = action => {
  const type = action.type.replace(PREFIXES.APICALL, '');
  const actionPayload = action.options || {};
  const restActionData = action.data;

  if (actionPayload.type) console.error("Overwritting 'type' key");
  if (actionPayload.data) console.error("Overwritting 'data' key");
  if (actionPayload.error) console.error("Overwritting 'error' key");

  return {
    request: () => ({ type: actionType.REQUEST(type), data: restActionData, ...actionPayload }),
    success: data => ({
      type: actionType.SUCCESS(type),
      payload: data,
      data: restActionData,
      ...actionPayload
    }),
    failure: (error, status) => ({
      type: actionType.ERROR(type),
      error,
      status,
      data: restActionData,

      ...actionPayload
    })
  };
};

// const detect401 = (response, action, auth, dispatch) => {
//   const { replayId } = action.options;
//   if (response.status === 401) {
//     if (auth.access) {
//       dispatch(deleteAccessToken());
//     }
//
//     if (!replayId) {
//       auth.refresh && !auth.refreshSent && dispatch(refreshUserAuth(auth.refresh));
//       dispatch(registerUnauthorizedAction(action));
//       return;
//     } else {
//       dispatch(deleteRefreshToken());
//       dispatch(setRedirectFlag('/logowanie'));
//     }
//   }
// };

const serializeData = actionOptions => {
  if (
    actionOptions.body &&
    typeof actionOptions.body !== 'string' &&
    actionOptions.headers['Content-Type'] === 'application/json'
  ) {
    actionOptions.body = JSON.stringify(actionOptions.body);
  }
};

const supplyAuthHeaders = (authToken, fetchOptions) => {
  if (authToken) {
    if (!fetchOptions.headers) fetchOptions.headers = {};
    fetchOptions.headers['Authorization'] = `Bearer ${authToken}`;
  }
};

const apiMiddleware = store => next => action => {
  const { dispatch, getState } = store;
  if (!action?.type) {
    console.error('Action without type', action);
  }

  if (action?.type?.toUpperCase?.()?.startsWith(PREFIXES.APICALL)) {
    let {
      url,
      transform,
      postprocess,
      actionPayload,
      resolve,
      reject,
      replayId,
      ...fetchOptions
    } = action.options;

    transform = transform || defaultTransform;
    serializeData(fetchOptions);
    const { request, success, failure } = createStoreActionCreators(action);
    const authToken = getUserToken(store.getState());

    supplyAuthHeaders(authToken, fetchOptions);

    fetchOptions.headers['Access-Control-Allow-Origin'] = '*';

    dispatch(request());
    let fetchPromise = fetch(url, fetchOptions)
      .then(async response => {
        if (response.ok) {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.toLowerCase().indexOf('application/json') !== -1) {
            return response.json();
          } else {
            return response;
          }
        }

        // detect401(response, action, auth, dispatch);

        const responseType = response.headers.get('content-type');
        let responseData = {};

        if (responseType?.toLowerCase().indexOf('application/json') !== -1) {
          responseData = await response?.json?.();
        }

        return throw new FetchError(
          `APICALL failed with status: ${response.status}`,
          responseData,
          response.status
        );
      })
      .then(json => {
        let data = transform(json || {}) || {};
        dispatch(success(data)) && resolve && resolve(data);
        postprocess && postprocess(data, dispatch, getState);
        return data;
      })
      .catch(error => {
        console.error({ error });

        dispatch(failure(error)) && reject && reject(error);
        return Promise.reject({ ...(error?.data || {}), status: error.status });
      });

    process.env.NODE_ENV !== 'production' && next(action); // Enable redux-dev-tools to perceive action
    return fetchPromise;
  }
  return next(action);
};

export default apiMiddleware;
