import produce from 'immer';
import actionType from 'utils/actionType';

import {
  ADMIN_EDIT_USER_FETCH_USER_DATA,
  ADMIN_EDIT_USER_FETCH_USER_INVOICE_DATA,
  ADMIN_EDIT_USER_SAVE_USER_DATA
} from 'modules/AdminPanel/AdminEditUser/actionType';

const INITIAL_STATE = {
  userData: null,
  userInvoiceData: null,
  statuses: {
    fetchingUserData: false,
    fetchingUserInvoiceData: false
  },
  errors: {
    afterFetchingUserData: false,
    afterFetchingUserInvoiceData: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(ADMIN_EDIT_USER_FETCH_USER_DATA): {
        state.userData = INITIAL_STATE.userData;
        state.errors.afterFetchingUserData = false;
        state.statuses.fetchingUserData = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_USER_FETCH_USER_DATA): {
        state.userData = action.payload.data?.[0];
        state.errors.afterFetchingUserData = false;
        state.statuses.fetchingUserData = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_USER_FETCH_USER_DATA): {
        state.errors.afterFetchingUserData = true;
        state.statuses.fetchingUserData = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_EDIT_USER_FETCH_USER_INVOICE_DATA): {
        state.userInvoiceData = INITIAL_STATE.userInvoiceData;

        state.errors.afterFetchingUserInvoiceData = false;
        state.statuses.fetchingUserInvoiceData = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_USER_FETCH_USER_INVOICE_DATA): {
        state.userInvoiceData = action.payload.data;

        state.errors.afterFetchingUserInvoiceData = false;
        state.statuses.fetchingUserInvoiceData = false;

        return state;
      }

      case actionType.ERROR(ADMIN_EDIT_USER_FETCH_USER_INVOICE_DATA): {
        state.errors.afterFetchingUserInvoiceData = true;
        state.statuses.fetchingUserInvoiceData = false;

        return state;
      }

      case actionType.SUCCESS(ADMIN_EDIT_USER_SAVE_USER_DATA): {
        state.userData = action.payload.data;

        return state;
      }

      default:
        return state;
    }
  });
