import produce from 'immer';

import actionType from 'utils/actionType';
import { USER_PAGE_TASKS_FETCH_TASKS } from 'modules/User/UserPageTasks/actionType';

const INITIAL_STATE = {
  tasks: null,
  stats: {
    from: 0,
    per_page: 10,
    to: 0,
    total: 0,
    current: 0
  },
  statuses: {
    fetchingUserPageTasks: false
  },
  errors: {
    afterFetchingUserPageTasks: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(USER_PAGE_TASKS_FETCH_TASKS): {
        state.statuses.fetchingUserPageTasks = true;
        state.errors.afterFetchingUserPageTasks = false;

        return state;
      }
      case actionType.SUCCESS(USER_PAGE_TASKS_FETCH_TASKS): {
        const actions = action?.payload?.data?.actions;
        state.statuses.fetchingUserPageTasks = false;
        state.errors.afterFetchingUserPageTasks = false;

        state.tasks = actions.data || [];
        state.stats = {
          from: actions.from,
          per_page: actions.per_page,
          to: actions.to,
          total: actions.total,
          current: actions.current_page
        };
        return state;
      }
      case actionType.ERROR(USER_PAGE_TASKS_FETCH_TASKS): {
        state.statuses.fetchingUserPageTasks = true;
        state.errors.afterFetchingUserPageTasks = true;

        return state;
      }

      default:
        return state;
    }
  });
