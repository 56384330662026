import produce from 'immer';

import {
  ADMIN_PANEL_FETCH_ADMINS,
  ADMIN_WEB_PAGES_ADD_ADMIN_TO_WEB_PAGE,
  ADMIN_WEB_PAGES_DELETE_ADMIN_FROM_WEB_PAGE,
  FETCH_ADMIN_PAGES
} from 'modules/AdminPanel/AdminWebPages/actionType';
import { pick, findIndex, filter } from 'lodash';

import actionType from 'utils/actionType';

const INITIAL_STATE = {
  webPages: null,
  pageStats: null,
  admins: null,
  adminsStats: null,
  statuses: {
    isFetchingWebPages: false,
    isAddingAdminToWebPage: {},
    isDeletingAdminFromWebPage: {},
    isFetchingAdmins: false
  },
  errors: {
    afterFetchingWebPages: false,
    afterAddingAdminToWebPage: {},
    afterDeletingAdminFromWebPage: {},
    afterFetchingAdmins: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(FETCH_ADMIN_PAGES): {
        state.webPages = INITIAL_STATE.webPages;
        state.pageStats = INITIAL_STATE.pageStats;

        state.errors.afterFetchingWebPages = false;
        state.statuses.isFetchingWebPages = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_ADMIN_PAGES): {
        const { pages } = action.payload.data;

        state.webPages = pages?.data;
        state.pageStats = pick(pages, ['total', 'from', 'to', 'per_page']);

        state.errors.afterFetchingWebPages = false;
        state.statuses.isFetchingWebPages = false;

        return state;
      }

      case actionType.ERROR(FETCH_ADMIN_PAGES): {
        state.errors.afterFetchingWebPages = true;
        state.statuses.isFetchingWebPages = false;

        return state;
      }

      case actionType.REQUEST(ADMIN_WEB_PAGES_ADD_ADMIN_TO_WEB_PAGE): {
        const { pageId } = action;

        state.statuses.isAddingAdminToWebPage[pageId] = true;
        state.errors.afterAddingAdminToWebPage[pageId] = false;

        return state;
      }

      case actionType.SUCCESS(ADMIN_WEB_PAGES_ADD_ADMIN_TO_WEB_PAGE): {
        const { data } = action.payload;
        const { pageId } = action;

        const index = findIndex(state.webPages, { id: action.body.page_id });
        state.webPages[index] = {
          ...state.webPages[index],
          ...data
        };

        state.statuses.isAddingAdminToWebPage[pageId] = false;
        state.errors.afterAddingAdminToWebPage[pageId] = false;

        return state;
      }

      case actionType.ERROR(ADMIN_WEB_PAGES_ADD_ADMIN_TO_WEB_PAGE): {
        const { pageId } = action;

        state.statuses.isAddingAdminToWebPage[pageId] = false;
        state.errors.afterAddingAdminToWebPage[pageId] = true;

        return state;
      }

      case actionType.REQUEST(ADMIN_WEB_PAGES_DELETE_ADMIN_FROM_WEB_PAGE): {
        const { pageId } = action;

        state.statuses.isDeletingAdminFromWebPage[pageId] = true;
        state.errors.afterDeletingAdminFromWebPage[pageId] = false;

        return state;
      }

      case actionType.SUCCESS(ADMIN_WEB_PAGES_DELETE_ADMIN_FROM_WEB_PAGE): {
        const { pageId, adminPageRelationId } = action;

        const pageIndex = findIndex(state.webPages, { id: pageId });
        state.webPages[pageIndex].admin_page = filter(
          state.webPages[pageIndex].admin_page,
          adminPageRelation => adminPageRelation.id !== adminPageRelationId
        );

        state.statuses.isDeletingAdminFromWebPage[pageId] = false;
        state.errors.afterDeletingAdminFromWebPage[pageId] = false;

        return state;
      }

      case actionType.ERROR(ADMIN_WEB_PAGES_DELETE_ADMIN_FROM_WEB_PAGE): {
        const { pageId } = action;

        state.statuses.isDeletingAdminFromWebPage[pageId] = false;
        state.errors.afterDeletingAdminFromWebPage[pageId] = true;

        return state;
      }

      case actionType.REQUEST(ADMIN_PANEL_FETCH_ADMINS): {
        state.admins = INITIAL_STATE.admins;
        state.adminsStats = INITIAL_STATE.adminsStats;

        state.errors.afterFetchingAdmins = false;
        state.statuses.isFetchingAdmins = true;

        return state;
      }

      case actionType.SUCCESS(ADMIN_PANEL_FETCH_ADMINS): {
        const { users } = action.payload.data;

        state.admins = users?.data;
        state.adminsStats = pick(users, ['total', 'from', 'to', 'per_page']);

        state.errors.afterFetchingAdmins = false;
        state.statuses.isFetchingAdmins = false;

        return state;
      }

      case actionType.ERROR(ADMIN_PANEL_FETCH_ADMINS): {
        state.errors.afterFetchingAdmins = true;
        state.statuses.isFetchingAdmins = false;

        return state;
      }

      default:
        return state;
    }
  });
