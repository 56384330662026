import './small_circle_loader.scss';
import React from 'react';

const SmallCircleLoader = ({ size = 20 }) => {
  return (
    <div className="loader-wrapper">
      <div className="loader" style={{ fontSize: size }}></div>
    </div>
  );
};

export default SmallCircleLoader;
