import React, { useCallback, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { mapValues } from 'lodash';

import useKeyPress from 'hooks/useKeyPress';
import flattenObject from 'utils/flattenObject';

import enMessages from 'translations/en.json';
// import plMessages from 'translations/_pl.json';
import devMessages from 'translations/dev.json';

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');
  require('@formatjs/intl-pluralrules/dist/locale-data/pl');
}

if (!Intl.RelativeTimeFormat) {
  require('@formatjs/intl-relativetimeformat/polyfill');
  require('@formatjs/intl-relativetimeformat/dist/locale-data/pl');
}

if (!Intl.DisplayNames) {
  require('@formatjs/intl-displaynames/polyfill');
  require('@formatjs/intl-displaynames/dist/locale-data/de');
}

const messages = mapValues(
  {
    pl: {},
    // pl: plMessages,
    en: enMessages,
    dev: devMessages
  },
  flattenObject
);
const IntlContext = React.createContext();

const modifierKeys = { ctrlKey: true, altKey: true };

const OwnIntlProvider = ({ children }) => {
  const [locale, setLocale] = useState(navigator.language.split(/[-_]/)[0]);
  const [lastLocale, setLastLocale] = useState('pl');

  const changeLocale = useCallback(
    newLocale => {
      if (!(newLocale in messages)) {
        throw new Error(`Wrong locale pass to selectLanguage: ${newLocale}`);
      } else {
        setLastLocale(locale);
        setLocale(newLocale);
      }
    },
    [setLastLocale, setLocale, locale]
  );

  const toggleDevMessages = useCallback(() => {
    changeLocale(locale === 'dev' ? lastLocale : 'dev');
  }, [changeLocale, locale, lastLocale]);

  useKeyPress('d', modifierKeys, toggleDevMessages);

  return (
    <IntlContext.Provider value={{ locale, changeLocale }}>
      <IntlProvider
        messages={messages[locale]}
        locale={locale === 'dev' ? 'pl' : locale}
        key={locale}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export default {
  ...IntlContext,
  Provider: OwnIntlProvider
};
