import apiCall from 'middleware/ApiCall';

import {
  AUTHORIZE_USER,
  AUTHORIZE_USER_WITH_TOKEN,
  LOGOUT,
  REGISTER_USER,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_CHECK_TOKEN,
  RESET_USER_PASSWORD_CHANGE_PASSWORD,
  VERIFICATION_EMAIL,
  RESEND_VERIFICATION_EMAIL
} from 'modules/Session/actionType';
import queryParamsStringify from 'utils/queryParamsStringify';

export const authorizeUser = ({ email, password, rememberToken }) =>
  apiCall.post(
    AUTHORIZE_USER,
    '/login',
    {
      body: { email, password }
    },
    {
      rememberToken
    }
  );

export const registerUser = ({ email, password }) =>
  apiCall.post(REGISTER_USER, '/register', {
    body: { email, password }
  });

export const resetUserPassword = email =>
  apiCall.post(RESET_USER_PASSWORD, '/password/create', {
    body: { email }
  });

export const resetUserPasswordCheckToken = token =>
  apiCall.get(RESET_USER_PASSWORD_CHECK_TOKEN, `/password/find/${token}`);

export const resetUserPasswordChangePassword = (token, password) =>
  apiCall.post(RESET_USER_PASSWORD_CHANGE_PASSWORD, '/password/reset', {
    body: { token, password }
  });

export const authorizeWithToken = () => apiCall.post(AUTHORIZE_USER_WITH_TOKEN, '/user/check');

export const logout = () => dispatch => {
  dispatch({ type: LOGOUT });
};

export const verificationEmail = ({ id, expires, signature }) =>
  apiCall.get(
    VERIFICATION_EMAIL,
    `/email/verify/${id}?${queryParamsStringify({ expires, signature })}`
  );

export const resendEmailVerificationUrl = () =>
  apiCall.post(RESEND_VERIFICATION_EMAIL, '/email/resend');
