import produce from 'immer';

import { pick } from 'lodash';

import actionType from 'utils/actionType';
import { FETCH_ADMIN_USERS } from 'modules/AdminPanel/AdminUsers/actionType';

const INITIAL_STATE = {
  users: null,
  pageStats: null,
  statuses: {
    isFetchingUsers: false
  },
  errors: {
    afterFetchingUsers: false
  }
};

export default (_state = INITIAL_STATE, action) =>
  produce(_state, state => {
    switch (action.type) {
      case actionType.REQUEST(FETCH_ADMIN_USERS): {
        state.users = INITIAL_STATE.users;
        state.pageStats = INITIAL_STATE.pageStats;

        state.errors.afterFetchingUsers = false;
        state.statuses.isFetchingUsers = true;

        return state;
      }

      case actionType.SUCCESS(FETCH_ADMIN_USERS): {
        const { users } = action.payload.data;

        state.users = users?.data;
        state.pageStats = pick(users, ['total', 'from', 'to', 'per_page']);

        state.errors.afterFetchingUsers = false;
        state.statuses.isFetchingUsers = false;

        return state;
      }

      case actionType.ERROR(FETCH_ADMIN_USERS): {
        state.errors.afterFetchingUsers = true;
        state.statuses.isFetchingUsers = false;

        return state;
      }

      default:
        return state;
    }
  });
