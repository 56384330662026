import './technical_message.scss';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as classnames from 'classnames';

import { ReactComponent as ErrorLightIcon } from 'icons/error_light.svg';
import { ReactComponent as ErrorIcon } from 'icons/error.svg';
import { ReactComponent as CloseIcon } from 'icons/exit.svg';

const mainClassName = 'technical-message';

export default class TechnicalMessage extends Component {
  static propTypes = {
    className: PropTypes.string,
    kind: PropTypes.oneOf(['normal', 'dark']),
    type: PropTypes.oneOf(['error']),
    title: PropTypes.string,
    content: PropTypes.string
  };

  static defaultProps = {
    className: '',
    kind: 'normal',
    type: 'error',
    title: '',
    content: ''
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  renderIcon = () => {
    const { kind } = this.props;

    const props = {
      height: 85,
      width: 85,
      className: `${mainClassName}__icon`
    };
    return kind === 'dark' ? <ErrorIcon {...props} /> : <ErrorLightIcon {...props} />;
  };

  render() {
    const { kind, onClose, title, content } = this.props;

    return (
      <div
        className={classnames(mainClassName, {
          [`${mainClassName}--dark`]: kind === 'dark'
        })}
      >
        {!!onClose && (
          <CloseIcon
            className={`${mainClassName}__close-icon`}
            onClick={onClose}
            width={25}
            height={25}
          />
        )}
        <div className={`${mainClassName}__icon-wrapper`}>{this.renderIcon()}</div>
        <div className={`${mainClassName}__message`}>
          {title && <h4 className={`${mainClassName}__message-title`}>{title}</h4>}
          <div className={`${mainClassName}__message-content`}>{content}</div>
        </div>
      </div>
    );
  }
}
