// import { size, filter, isArray } from 'lodash'

import messages from "./fieldValidationMessages";

const required = (
  value,
  allValues,
  props,
  message = messages.FORMS__VALIDATION__INPUT_REQUIRED
) => {
  return value ? undefined : message;
};

const createRequired = (
  message = messages.FORMS__VALIDATION__INPUT_REQUIRED
) => (...args) => required(...args, message);

// export const maxLength = max => value =>
//   value && value.length > max
//     ? {
//       message: messages.VALIDATION_INPUT_MIN_LENGTH,
//       values: {
//         min
//       }
//     }
//     : undefined;

// export const minLength = min => value =>
//   value && value.length < min
//     ? {
//       message: messages.VALIDATION_INPUT_MIN_LENGTH,
//       values: {
//         min
//       }
//     }
//     : undefined;

const number = (value) =>
  value && isNaN(Number(value))
    ? messages.FORMS__VALIDATION__INPUT_NUMBER
    : undefined;

// export const minValue = min => value =>
//   value && value < min ? messages.VALIDATION_INPUT_MIN_VALUE : undefined;

const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? messages.FORMS__VALIDATION__INPUT_EMAIL
    : undefined;

const postalCode = (value) =>
  value && !/^\d{2}-\d{3}$/i.test(value)
    ? messages.FORMS__VALIDATION__INPUT_POSTAL_CODE
    : undefined;

const nip = (value) =>
  value &&
  !/^((\d{3}[- ]\d{3}[- ]\d{2}[- ]\d{2})|(\d{3}[- ]\d{2}[- ]\d{2}[- ]\d{3}))$/i.test(
    value
  )
    ? messages.FORMS__VALIDATION__NIP
    : undefined;

// export const alphaNumeric = value =>
//   value && /[^a-zA-Z0-9 ]/i.test(value) ? messages.VALIDATION_INPUT_ALPHANUMERIC : undefined;
//
// export const arrayMinSize = (min, validItem) => value => {
//   let items = value;
//   if (validItem) items = filter(items, validItem);
//
//   return size(items) < min
//     ? { message: messages.VALIDATION_ARRAY_MIN_SIZE, values: { min } }
//     : undefined;
// };

// export const phoneNumber = value =>
//   value && !/^(0|[1-9][0-9]{9})$/i.test(value)
//     ? 'Invalid phone number, must be 10 digits'
//     : undefined;

const fieldValidation = {
  required,
  createRequired,
  number,
  email,
  postalCode,
  nip,
};

export default fieldValidation;
