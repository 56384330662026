import "modules/Session/Login/login.scss";

import React from "react";
import { Helmet } from "react-helmet";
import { Field, Form, reduxForm } from "redux-form";
import { useLocation } from "react-router";
import { trim } from "lodash";
import { FormattedMessage, defineMessage } from "react-intl";

import InputComponent from "components/FormComponents/InputComponent";
import BasicLayout from "components/BasicLayout/BasicLayout";
import Button from "components/Button/Button";
import LinkButton from "components/LinkButton/LinkButton";

import { authorizeUser } from "modules/Session/actions";

import { useFieldValidate } from "libs/reduxForm/hooks/useFieldValidate";
import fieldValidation from "libs/reduxForm/fieldValidation";
import CheckboxFormComponent from "components/FormComponents/CheckboxFormComponent";
import useSubmitAction from "libs/reduxForm/hooks/useSubmitAction";

import { ReactComponent as ChevronCircleLeftIcon } from "./circle-left-solid-arrow.svg";

const mainClassName = "login";
const mainMessageId = "session.login";

const loginEmailRequired = fieldValidation.createRequired(
  defineMessage({
    id: `${mainMessageId}.email_field_is_required`,
    defaultMessage: "Wprowadź adres email",
  })
);

const loginPasswordRequired = fieldValidation.createRequired(
  defineMessage({
    id: `${mainMessageId}.password_field_is_required`,
    defaultMessage: "Wprowadź hasło",
  })
);

const Login = ({ handleSubmit, submitting }) => {
  const location = useLocation();

  const [onSubmit] = useSubmitAction({
    action: authorizeUser,
    errorrFormattedMessage: (
      <FormattedMessage
        id={`${mainMessageId}.wrong_data`}
        defaultMessage="Wprowadzone dane użytkownika są nieprawidłowe. Spróbuj ponownie"
      />
    ),
  });

  const emailValidation = useFieldValidate([
    loginEmailRequired,
    fieldValidation.email,
  ]);
  const passwordValidate = useFieldValidate([loginPasswordRequired]);

  return (
    <>
      <FormattedMessage
        id={`${mainMessageId}.page_title`}
        defaultMessage="Panel logowania"
      >
        {(chunks) => (
          <Helmet>
            <title>{chunks}</title>
          </Helmet>
        )}
      </FormattedMessage>
      <BasicLayout
        headerActions={
          <>
            <LinkButton
              className={`${mainClassName}__register-button`}
              to={{
                ...location,
                pathname: "/register",
              }}
              withBrackets
              kind="outline"
              disabled={submitting}
            >
              <FormattedMessage
                id={`${mainMessageId}.register_button`}
                defaultMessage="Zarejestruj się"
              />
            </LinkButton>
          </>
        }
      >
        <div className={mainClassName}>
          <h2 className={`${mainClassName}__form-title`}>
            <FormattedMessage
              id={`${mainMessageId}.title`}
              defaultMessage="Zaloguj się"
            />
          </h2>
          <div className={`${mainClassName}__login-form-wrapper`}>
            <Form
              className={`${mainClassName}__form`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Field
                auto
                name="email"
                component={InputComponent}
                label={
                  <FormattedMessage
                    id={`${mainMessageId}.email_label`}
                    defaultMessage="E-mail"
                  />
                }
                validate={emailValidation}
                autoComplete="email"
                autoFocus
              />
              <Field
                name="password"
                component={InputComponent}
                type="password"
                label={
                  <FormattedMessage
                    id={`${mainMessageId}.password_label`}
                    defaultMessage="Hasło"
                  />
                }
                validate={passwordValidate}
                normalize={(value) => trim(value)}
                autoComplete="current-password"
              />
              <Field
                name="rememberToken"
                component={CheckboxFormComponent}
                label={
                  <FormattedMessage
                    id={`${mainMessageId}.remember_token_checkbox_label`}
                    defaultMessage="zapamiętaj dane logowania"
                  />
                }
                hideEmptyError
              />
              <div className={`${mainClassName}__reset-password`}>
                <LinkButton to="/reset-pass" withBrackets>
                  <FormattedMessage
                    id={`${mainMessageId}.reset_password`}
                    defaultMessage="Resetuj hasło"
                  />
                </LinkButton>
              </div>
              <div className={`${mainClassName}__submit-button-wrapper`}>
                <Button
                  type="submit"
                  withBrackets
                  kind="outline"
                  loading={submitting}
                >
                  <FormattedMessage
                    id={`${mainMessageId}.submit_button`}
                    defaultMessage="Zaloguj się"
                  />
                </Button>
              </div>
            </Form>
          </div>
          <div className={`${mainClassName}__back-wrapper`}>
            <LinkButton href="https://forsecure.pl/" kind="link">
              <ChevronCircleLeftIcon height={20} />
              <span className={`${mainClassName}__back-text`}>
                <FormattedMessage
                  id={`${mainMessageId}.go_back_to_main_page`}
                  defaultMessage="Wróc do strony głównej"
                />
              </span>
            </LinkButton>
          </div>
        </div>
      </BasicLayout>
    </>
  );
};

export default reduxForm({
  form: "LoginUser",
})(Login);
