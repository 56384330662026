export const mainPaths = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  REGISTER: '/register',
  REGISTER_CONFIRM: '/register-confirm',
  RESET_PASSWORD: '/reset-pass',
  RESET_PASSWORD_TOKEN: '/reset-pass/confirm/:token',
  EMAIL_VERIFICATION: '/email/verify/:id',
  THANKS: '/thanks',
  PRIVACY_POLICY: '/regulamin',

  ADMIN_PANEL: '/admin',
  USER_PANEL: '/user'
};
